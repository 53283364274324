import React, {useState} from "react"
import {Box} from '../../packages'
import {BoxProps} from '../../packages/Box/types'
import {LoadingOutlined} from '@ant-design/icons'
import {sleep} from '../../util/utils'


interface iButton {
    name: string
    type: string 
    wordBreak?: boolean
    icon?: React.ReactNode
    iconhover?: React.ReactNode
    loading?: boolean
    height?: string
    borderRadius?: string
    onClick?: () => void
    nameClassName?: string
    onMouseOver?: () => void
    onMouseLeave?: () => void
}
export const Button:React.FC<iButton & BoxProps> = ({ onMouseOver,onMouseLeave, onClick, name, nameClassName, height, borderRadius, type, icon, iconhover, loading, wordBreak = false, ...props }) => {
    const [isHover, setHover] = useState(false)
    const [isLock, setLock] = useState(false)
    
    let border = '',borderHover = '1px solid rgba(242, 207, 217, 0.3)', background = '', 
        backgroundHover = '', color = '',colorHover='rgb(224,219,235)', cursor = 'pointer'
    switch (type) {
        case 'dark':
            border = '1px solid rgba(242, 207, 217, 0.4)'
            background = ' linear-gradient(90deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%)'
            backgroundHover = ' linear-gradient(92.16deg, #ECB9D8 -2.04%, #5D63F3 99.99%)'
            color = 'rgb(199,199,199)'
            break
        case 'light':
            border = '1px solid rgba(242, 207, 217, 0.3)'
            background = 'linear-gradient(92.16deg, #ECECEC -2.04%, #B3B2CC 99.99%)'
            backgroundHover = 'linear-gradient(92.16deg, #ECB9D8 -2.04%, #5D63F3 99.99%)'
            color = 'rgb(68,67,72)'
            break
        case 'disabled':
            border = '1px solid transparent'
            background = 'linear-gradient(92.16deg, rgba(236, 236, 236, 0.15) -2.04%, rgba(179, 178, 204, 0.15) 99.99%), linear-gradient(129.62deg, rgba(29, 27, 27, 0.5) 27.35%, rgba(37, 37, 43, 0.5) 104.22%)'
            color = 'rgb(157, 156, 158)'
            break   
        default:
            break
    }    

    const handleClick = async() => {
        if(!isLock && type !== 'disabled') {
            onClick && onClick()
            setLock(true)
        }
        await sleep()
        setLock(false)
    }
    return (
        <Box 
            {...props}
            onClick={handleClick}
            onMouseOver={() => {
                setHover(true)
                onMouseOver && onMouseOver()
            }}
            onMouseLeave={() => {
                setHover(false)
                onMouseLeave && onMouseLeave()
            }}
            height={height || "40px"}
            border={isHover ? borderHover : border} 
            background={isHover ? backgroundHover : background} 
            className="center"
            style={{textAlign:'center', cursor, pointerEvents: 'all'}}
            borderRadius={borderRadius || '4px'}
        >
            {
                loading ? <LoadingOutlined 
                            style={{ 
                                color: type === 'light' ? (isHover ? '#fff' : '#000') : '#fff'
                            }}/> : 
                <>
                    {isHover ? iconhover : icon}
                    <span 
                        className={wordBreak ? `fz12 fw600 ${nameClassName}` : `fz12 fw600 word-break ${nameClassName}`}  
                        style={{ color:isHover ? colorHover : color }}>
                        {name}
                    </span>
                </>
            }
        </Box>
    )
}