import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../store'
import {iHomeState} from '../../types'


const initialState: iHomeState = {
  count: 0,
  teamActive: {
    isDetail: false,
    idx: 0
  },
  anchorsIndex: 0
}

export const homeReducer = createSlice({
  name: 'homeReducer',
  initialState,
  reducers: {
    countAction: (state, action: PayloadAction<iHomeState['count']>) => {
      state.count = action.payload
    },
    teamActiveAction: (state, action: PayloadAction<iHomeState['teamActive']>) => {
      state.teamActive = action.payload
    },
    anchorsIndexAction: (state, action: PayloadAction<iHomeState['anchorsIndex']>) => {
      state.anchorsIndex = action.payload
    },
  },
})

export const { countAction, teamActiveAction, anchorsIndexAction } = homeReducer.actions

export const selectCount = (state: RootState) => state.homeReducer.count
export const selectTeamActive = (state: RootState) => state.homeReducer.teamActive
export const selectAnchorsIndex = (state: RootState) => state.homeReducer.anchorsIndex


export default homeReducer.reducer
