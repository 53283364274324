import React, { useState, useEffect } from 'react'
import { svg_circle4, svg_circle2, svg_circle1, svg_circle3, svg_dashed } from '../../../assets/svg'
import { useSpring, config, animated } from '@react-spring/web'
import { DURATION } from '../../../config'

interface iCircles {
    activeIdx: number
    direction: string
}
export const Circles:React.FC<iCircles> = ({ activeIdx, direction }) => {

    const [springs0, api0] = useSpring(() => ({ from: { left: 0, top: 0, right: 0, bottom: 0, } }))
    const [springs1, api1] = useSpring(() => ({ from: { width: 664, height: 664, left: 0, top: 0 } }))
    const [springs2, api2] = useSpring(() => ({ from: { width: 550, height: 550, left: 57, top: 57 } }))
    const [springs3, api3] = useSpring(() => ({ from: { width: 502, height: 502, left: 81, top: 81 } }))
    const [springs4, api4] = useSpring(() => ({ from: { width: 342, height: 342, left: 161, top: 161 } }))
  



    useEffect(() => {
        if(direction === 'up') {
            switch (activeIdx) {
                case 0:
                    api0.start({ left: 0, top: 0, right: 0, bottom: 0, config: { duration: DURATION } })
                    api1.start({ width: 664, height: 664, left: 0, top: 0 , config: { duration: DURATION } })
                    api2.start({ width: 550, height: 550, left: 57, top: 57, config: { duration: DURATION } })
                    api3.start({ width: 502, height: 502, left: 81, top: 81, config: { duration: DURATION }})
                    api4.start({ width: 342, height: 342, left: 161, top: 161, config: { duration: DURATION }})
                    break;
                case 1:
                    api0.start({ left: -1400, top: -700, right: 0, bottom: 0, config: { duration: DURATION } })
                    api1.start({ width: 1380, height: 1380, left: -340, top: -338, config: { duration: DURATION } })
                    api2.start({ width: 1306, height: 1306, left: -321, top: -321, config: { duration: DURATION } })
                    api3.start({ width: 778, height: 778, left: -178, top: -200,  config: { duration: DURATION }})
                    api4.start({ width: 489, height: 489, left: -100, top: -134,  config: { duration: DURATION }})
                    break
                case 2: 
                    api0.start({ left: 0, top: 0, right: 0, bottom: 0, config: { duration: DURATION } })
                    api1.start({ width: 1153, height: 1153, left: -242, top: -180, config: { duration: DURATION } })
                    api2.start({ width: 1052, height: 1052, left: -186, top: -131, config: { duration: DURATION } })
                    api3.start({ width: 1004, height: 1004, left: -160, top: -107,  config: { duration: DURATION }})
                    api4.start({ width: 687, height: 687, left: -12, top: 51,  config: { duration: DURATION }})
                    break
                case 3: 
                    api0.start({ left: 0, top: 0, right: -1400, bottom: -700, config: { duration: DURATION } })
                    api1.start({ width: 1209, height: 1209, left: -244, top: -244, config: { duration: DURATION } })
                    api2.start({ width: 789, height: 789, left: -61, top: -61, config: { duration: DURATION } })
                    api3.start({ width: 748, height: 748, left: -41, top: -41, config: { duration: DURATION }})
                    api4.start({ width: 496, height: 496, left: 84, top: 84,  config: { duration: DURATION }})
                    break
            }
        }
        if(direction === 'down') {
                switch(activeIdx) {
                    case 0:
                        
                        break
                    case 1:
                        api0.start({ left: -1400, top: -700, right: 0, bottom: 0, config: { duration: DURATION } })
                        api1.start({ width: 1380, height: 1380, left: -340, top: -338, config: { duration: DURATION } })
                        api2.start({ width: 1306, height: 1306, left: -321, top: -321, config: { duration: DURATION } })
                        api3.start({ width: 778, height: 778, left: -178, top: -200,  config: { duration: DURATION }})
                        api4.start({ width: 489, height: 489, left: -100, top: -134,  config: { duration: DURATION }})
                        break
                    case 2: 
                        api0.start({ left: 0, top: 0, right: 0, bottom: 0, config: { duration: DURATION } })
                        api1.start({ width: 1153, height: 1153, left: -242, top: -180, config: { duration: DURATION } })
                        api2.start({ width: 1052, height: 1052, left: -186, top: -131, config: { duration: DURATION } })
                        api3.start({ width: 1004, height: 1004, left: -160, top: -107,  config: { duration: DURATION }})
                        api4.start({ width: 687, height: 687, left: -12, top: 51,  config: { duration: DURATION }})
                        break
                    case 3: 
                        api0.start({ left: 0, top: 0, right: -1400, bottom: -700, config: { duration: DURATION } })
                        api1.start({ width: 1209, height: 1209, left: -244, top: -244, config: { duration: DURATION } })
                        api2.start({ width: 789, height: 789, left: -61, top: -61, config: { duration: DURATION } })
                        api3.start({ width: 748, height: 748, left: -41, top: -41, config: { duration: DURATION }})
                        api4.start({ width: 496, height: 496, left: 84, top: 84,  config: { duration: DURATION }})
                        break

                }
        }
    },[activeIdx,direction])    
    return (
            <animated.div className="circle_container" style={{ ...springs0 }} >
                <div className="circle_bg_start" style={{  }}/> 
                <animated.img src={svg_circle1} alt=""  className="circle1_rotate absolute" style={{ ...springs1,}}/>
                <animated.img src={svg_circle2} alt=""  className="circle2_rotate absolute" style={{ ...springs2 }}/>
                <animated.img src={svg_circle3} alt=""  className="circle3_rotate absolute" style={{ ...springs3 }}/>
                <animated.img src={svg_circle4} alt=""  className="circle4_rotate absolute" style={{ ...springs4 }}/>
            </animated.div>
      
    )
}