import React, {useEffect, useState} from "react";
import {Modal} from "@/components/Modal";
import {Box, Text} from "@/packages";
import {Row} from "@/components/Layout";
import {Button} from "@/components/Button";

interface iConfirm {
    open: boolean
    title: string
    onConfirm: () => void
    onCancel: () => void
    confirmName?: string
    loading?: boolean
}

export const Confirm: React.FC<iConfirm> = ({open, title, onConfirm, onCancel, confirmName, loading}) => {
    return <Modal
        width={352}
        visible={open}
        onCancel={onCancel}
    >
        <Box height="186px" p="40px" className='fx-col ai-ct '>
            <Text className='fz14 white oct8'>{title}</Text>
            <Row className='' mt="32px">
                <Button
                    width="120px"
                    onClick={onCancel}
                    name="Cancel"
                    height="56px"
                    type="dark"
                />
                <Button
                    ml="60px"
                    width="120px"
                    onClick={onConfirm}
                    loading={loading}
                    name={confirmName ?? "Confirm"}
                    height="56px"
                    type="light"
                />
            </Row>
        </Box>
    </Modal>
}