export const MEMBERSHIP_2_ABI = [{
    "inputs": [{"internalType": "contract IERC721", "name": "_lancetPass", "type": "address"}, {"internalType": "contract ILancetStaking", "name": "_lancetStaking1", "type": "address"}, {
        "internalType": "contract ILancetStaking",
        "name": "_lancetStaking2",
        "type": "address"
    }], "stateMutability": "nonpayable", "type": "constructor"
}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {"indexed": true, "internalType": "uint256", "name": "startTimestamp", "type": "uint256"}, {"indexed": true, "internalType": "uint256", "name": "endTimestamp", "type": "uint256"}, {
        "indexed": false,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }],
    "name": "MembershipPurchase",
    "type": "event"
}, {"anonymous": false, "inputs": [{"indexed": true, "internalType": "address", "name": "previousOwner", "type": "address"}, {"indexed": true, "internalType": "address", "name": "newOwner", "type": "address"}], "name": "OwnershipTransferred", "type": "event"}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "owner", "type": "address"}, {"indexed": true, "internalType": "uint256", "name": "startTimestamp", "type": "uint256"}, {"indexed": true, "internalType": "uint256", "name": "endTimestamp", "type": "uint256"}],
    "name": "UserMembershipPurchase",
    "type": "event"
}, {
    "inputs": [{"internalType": "uint256", "name": "purchaseDays", "type": "uint256"}, {"internalType": "uint256", "name": "price", "type": "uint256"}, {"internalType": "bool", "name": "onSale", "type": "bool"}],
    "name": "addMembership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [],
    "name": "getAllMembershipTypes",
    "outputs": [{
        "components": [{"internalType": "uint256", "name": "membershipDays", "type": "uint256"}, {"internalType": "bool", "name": "onSale", "type": "bool"}, {"internalType": "uint256", "name": "membershipPrice", "type": "uint256"}],
        "internalType": "struct LancetMembership.MembershipType[]",
        "name": "",
        "type": "tuple[]"
    }],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "holderDiscount", "outputs": [{"internalType": "uint8", "name": "", "type": "uint8"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [{"internalType": "uint256", "name": "purchaseDays", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }], "name": "holderMembershipPurchase", "outputs": [], "stateMutability": "payable", "type": "function"
}, {"inputs": [], "name": "holderMembershipPurchaseEnable", "outputs": [{"internalType": "bool", "name": "", "type": "bool"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [],
    "name": "lancetPass",
    "outputs": [{"internalType": "contract IERC721", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "lancetStaking1", "outputs": [{"internalType": "contract ILancetStaking", "name": "", "type": "address"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [],
    "name": "lancetStaking2",
    "outputs": [{"internalType": "contract ILancetStaking", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [{"internalType": "uint256", "name": "", "type": "uint256"}], "name": "membershipCategories", "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "name": "membershipState",
    "outputs": [{"internalType": "uint256", "name": "membershipDays", "type": "uint256"}, {"internalType": "bool", "name": "onSale", "type": "bool"}, {"internalType": "uint256", "name": "membershipPrice", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "owner", "outputs": [{"internalType": "address", "name": "", "type": "address"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "name": "passMembershipEndAt",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [{"internalType": "uint256", "name": "purchaseDays", "type": "uint256"}], "name": "removeMembership", "outputs": [], "stateMutability": "nonpayable", "type": "function"}, {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {"inputs": [{"internalType": "uint8", "name": "discount", "type": "uint8"}], "name": "setHolderDiscount", "outputs": [], "stateMutability": "nonpayable", "type": "function"}, {
    "inputs": [],
    "name": "setHolderMembershipPurchaseEnable",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {"inputs": [{"internalType": "contract IERC721", "name": "_lancetPass", "type": "address"}], "name": "setLancetPass", "outputs": [], "stateMutability": "nonpayable", "type": "function"}, {
    "inputs": [{"internalType": "contract ILancetStaking", "name": "_lancetStaking1", "type": "address"}],
    "name": "setLancetStaking1",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {"inputs": [{"internalType": "contract ILancetStaking", "name": "_lancetStaking2", "type": "address"}], "name": "setLancetStaking2", "outputs": [], "stateMutability": "nonpayable", "type": "function"}, {
    "inputs": [],
    "name": "setUserMembershipPurchaseEnable",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {"inputs": [{"internalType": "address", "name": "newOwner", "type": "address"}], "name": "transferOwnership", "outputs": [], "stateMutability": "nonpayable", "type": "function"}, {
    "inputs": [{"internalType": "uint256", "name": "purchaseDays", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
    }, {"internalType": "bool", "name": "onSale", "type": "bool"}], "name": "updateMembership", "outputs": [], "stateMutability": "nonpayable", "type": "function"
}, {"inputs": [{"internalType": "address", "name": "", "type": "address"}], "name": "userMembershipEndAt", "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}], "stateMutability": "view", "type": "function"}, {
    "inputs": [{
        "internalType": "uint256",
        "name": "purchaseDays",
        "type": "uint256"
    }], "name": "userMembershipPurchase", "outputs": [], "stateMutability": "payable", "type": "function"
}, {"inputs": [], "name": "userMembershipPurchaseEnable", "outputs": [{"internalType": "bool", "name": "", "type": "bool"}], "stateMutability": "view", "type": "function"}, {"inputs": [], "name": "withdraw", "outputs": [], "stateMutability": "nonpayable", "type": "function"}]