import {CoinbaseWallet} from '@web3-react/coinbase-wallet'
import {GnosisSafe} from '@web3-react/gnosis-safe'
import {MetaMask} from '@web3-react/metamask'
import {Network} from '@web3-react/network'
import {WalletConnect} from '@web3-react/walletconnect'
import type {Connector} from '@web3-react/types'
import {v4 as uuidv4} from 'uuid'
import {OrOrderBy} from "../types"

export function getName(connector: Connector) {
    if (connector instanceof MetaMask) return 'MetaMask'
    if (connector instanceof WalletConnect) return 'WalletConnect'
    if (connector instanceof CoinbaseWallet) return 'Coinbase Wallet'
    if (connector instanceof Network) return 'Network'
    if (connector instanceof GnosisSafe) return 'Gnosis Safe'
    return 'Unknown'
}

export function createNonce(): string {
    return uuidv4().replace(/-/g, '').substring(0, 16)
}

export const ignoreCaseEqual = (a: string, b: string): boolean => {
    return !!a && !!b && a.toLowerCase() === b.toLowerCase()
}

export const ignoreCaseIncludes = (array: string[], searchElement: string, fromIndex?: number) => {
    return array.map(item => item.toLowerCase()).includes(searchElement.toLowerCase(), fromIndex)
}

function smartCompare(a: any, b: any) {
    if (!isNaN(Number(a)) && !isNaN(Number(b))) {
        return Number(a) > Number(b)
    }
    return a > b
}

export const orderByField = (filtered: any[], ...orderBys: OrOrderBy[]) => {
    let sortFiltered = JSON.parse(JSON.stringify(filtered))
    if (orderBys && orderBys.length > 0) {
        sortFiltered = sortFiltered.sort((c1: any, c2: any) => {
            for (let orderBy of orderBys) {
                if (!orderBy) {
                    return -1;
                }
                let field = orderBy.field
                let asc = orderBy.asc
                if (c1[field] == c2[field]) {
                    continue
                }
                return [-1, 1][Number(smartCompare(c1[field], c2[field]))] * [-1, 1][Number(asc)]
            }
        })
    }
    return sortFiltered;
}

export function cutAddress(address?: string, start: number = 6, end: number = 4):string {
    if(address && address?.length > (start + end)) {
        return address ? `${address.substring(0, start)}...${address.substring(address.length - end)}` : '--'
    }else {
        return address || ''
    }
}

export const openWindow = (url: string) => {
    window.open(url)
}

export const sleep = (ms?: number):Promise<boolean> => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms || 1000)
    })
}

export const leftDaysFromNow = (seconds: number): number => {
    const days = Math.ceil((seconds - Date.now() / 1000) / 86400)
    return Math.max(days, 0)
}
