import React, {useCallback, useEffect, useRef, useState} from 'react'
import { Box, Text, Image, Grid } from '../../../packages'
import {Row, Col, DashLine, Button, ColorTitle, calculateDuration} from '../../../components'
import styled from 'styled-components'
import { img_unactivation,img_activation, img_clock } from '../../../assets/img'
import {  } from '../../../util/utils'
import { useCountdown } from '../../../hooks'

const Box1 = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 376px;
    padding: 40px;
    background: linear-gradient(129.62deg, #1D1B1B 27.35%, #25252B 104.22%);
    border: 1px solid rgba(242, 207, 217, 0.3);
`
const Box2 = styled(Box)`
    width: 100%;
    height: 296px;
    background: linear-gradient(129.62deg, #1D1B1B 27.35%, #25252B 104.22%);
    border: 1px solid rgba(242, 207, 217, 0.3);
`
const TimeWrapper = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 116px;
    height: 24px;
    background: linear-gradient(92.16deg, #ECB9D8 -2.04%, #5D63F3 99.99%);
    border-radius: 8px 8px 8px 0px;
    position: absolute;
    right: -8px;
    top: -12px
`
export const StakeCountdown = () => {
    const duration = useCountdown(1795964678394, 1000)
    return (
        <TimeWrapper>
            <Row>
                <Image src={img_clock} height={12} width={12}/>
                <Text className='fz12 oct8' ml="4px">{ `${duration.days()}D:${duration.hours()}H:${duration.minutes()}M`}</Text>
            </Row>
        </TimeWrapper>
    )
}
const CountdownWrapper = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 116px;
    height: 24px;
    background: linear-gradient(92.16deg, #ECB9D8 -2.04%, #5D63F3 99.99%);
    border-radius: 8px 8px 8px 0px;
    position: absolute;
    right: 35px;
    top: -20px
`
export const StakingCountdown = ({eventTime, onEnding}:{eventTime: number, onEnding?: () => void}) => {
    const [duration, setDuration] = useState(calculateDuration(eventTime))
    const timerRef: any = useRef(0)

    const timerCallback = useCallback(() => {
        setDuration(calculateDuration(eventTime))
    }, [eventTime])

    useEffect(() => {
        timerRef.current = setInterval(timerCallback, 1000)
        return () => {
            clearInterval(timerRef.current)
        }
    }, [eventTime])

    useEffect(() => {
        if (duration.days() === 0 && duration.hours() === 0 && duration.minutes() === 0 && duration.seconds() === 0) {
            onEnding && onEnding()
        }
    },[duration])
    return (
        <CountdownWrapper>
            <Row>
                <Image src={img_clock} height={12} width={12}/>
                { Math.ceil(duration.asDays()) > 0 ?
                    <Text className='fz12 oct8' ml="4px">{ `${Math.ceil(duration.asDays())}D:${duration.hours()}H:${duration.minutes()}M`}</Text>
                    :
                    <Text className='fz12 oct8' ml="4px">{ `${duration.hours()}H:${duration.minutes()}M:${duration.seconds()}S`}</Text>
                }
            </Row>
        </CountdownWrapper>
    )
}
export const RightInterests = () => {
    return (
        <Box2 className="fx-col w100">
            {['FOMO Bot','Wallet Alert','Floor Price Monitor','Giveaway Spots'].map((item: string, idx: number) => (
                <Col key={item}>
                    <Row className='fx-row center w100' height="74px">
                        <Image src={img_activation}/>
                        <Text ml="8px" className="linear_color linear_gradient fz16 oct8">{item}</Text>
                    </Row>
                    { idx !== 3 && <DashLine/> }
                </Col>
            ))}
        </Box2>       
    )
}

export const SelectRightsInterests = () => {
    const [isHover, setHover] = useState<boolean>(false)  

    return (
        <Grid className='fx-row ai-ct jc-sb' gridTemplateColumns={['1fr 1fr']} gridGap="40px">
            <Box className='fx-col jc-sb h100'>
                {
                    [1,2,3,4].map((item:number) => (
                        <Box className='relative'>
                            <Button
                                name={`Stake Level ${item}`}
                                type="light"
                                width="372px"
                                height="56px"
                                // onMouseOver
                                // onMouseLeave
                            />
                            <StakeCountdown/>
                        </Box>
                    ))
                }
            </Box>
            <RightInterests/>
        </Grid>
    )
}

interface iStakingContainer {
    children: React.ReactNode
}
export const StakingContainer:React.FC<iStakingContainer> = ({ children }) => {
    return (
        <Box className='w100 center'>
            <Col mt="56px" mb="108px" className='ai-ct' width="864px">
                <ColorTitle title="STAKING"/>
                <Text className='linear_gray linear_gradient fz12 oct4' mt="32px">Staking is for Lancet Member</Text>
                <Text className='linear_gray linear_gradient fz12 oct4' mt="10px">Only Longer Period for Higher Benefit</Text>
                <Box1 mt="48px">
                    { children }
                </Box1>
            </Col>
        </Box>
    )
}