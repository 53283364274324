import {Box} from '../../../packages'
import {DURATION, home_page1_left_1, home_page1_left_2} from '../../../config'
import {Left} from '../../../components'
import {useEffect} from 'react'
import {animated, useSpring} from '@react-spring/web'
import {useAppSelector} from "../../../store/hooks";
import {selectMintTimeEnded} from "../../../store/reducer";
import {FreeStaking} from "@/pages/Membership/components";

export const Screen1:React.FC<{direction: string, activeIdx: number}> = ({direction, activeIdx}) => {
    const mintTimeEnded = useAppSelector(selectMintTimeEnded)

    const [springs0, api0] = useSpring(() => ({ from: { y: -150, opacity: 0 }, to: { y: 0, opacity: 1 }, config: { duration: DURATION } }))
    const [springs1, api1] = useSpring(() => ({ from: { y: -50, opacity: 0 }, to: { y: 0, opacity: 1 }, config: { duration: DURATION } }))
    const [springs2, api2] = useSpring(() => ({ from: { y: -80, opacity: 0 }, to: { y: 0, opacity: 1 }, config: { duration: DURATION}, delay: DURATION - 500  }))

    
    useEffect(() => {
        if(direction === 'up') {
            if(activeIdx === 0) {
                api0.start({ from: { y: 150, opacity: 0 }, to: { y: 0, opacity: 1 }, config: { duration: DURATION } })
                api1.start({ from: { y: 80, opacity: 0 }, to: { y: 0, opacity: 1 }, config: { duration: DURATION } })
                api2.start({ from: { y: 50, opacity: 0 }, to: { y: 0, opacity: 1 }, config: { duration: DURATION}, delay: DURATION - 500  })
            }
        }   
        if(direction === 'down') {
            if(activeIdx === 1) {
                api0.start({ from: { opacity: 1 }, to: { opacity: 0 }, config: { duration: 500 } })
                api1.start({ from: { opacity: 1 }, to: { opacity: 0 }, config: { duration: 500 } })
                api2.start({ from: { opacity: 1 }, to: { opacity: 0 }, config: { duration: 500 } })
            }
        }
    },[direction, activeIdx])

    return (
        <Box>
            <Box className="circle_container">
                <animated.div style={{...springs0}}>
                    <FreeStaking/>
                </animated.div>
            </Box>
            {!mintTimeEnded &&
                <Left className='fx-col'>
                    <animated.div className='fz10 text_linear pre_wrap lh32 fw700' style={{...springs1}}>{home_page1_left_1}</animated.div>
                    <animated.div className='fz10 text_linear pre_wrap lh32 fw700' style={{...springs2}}>{home_page1_left_2}</animated.div>
                </Left>
            }

        </Box>
    )
}