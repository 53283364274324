import React, {useEffect, useState} from 'react'
import {Text} from '../../packages'
import {Row} from '../index'
import {leftDaysFromNow} from '../../util/utils'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {CurrentTokenAction, selectCurrentToken, selectTokens} from '../../store/reducer'
import {TokenSelect} from "./TokenSelect";


export const LeftDays = () => {
    const [leftDays, setLeftDays] = useState<number>(0)
    const dispatch = useAppDispatch()
    const tokens = useAppSelector(selectTokens)
    const currentToken = useAppSelector(selectCurrentToken)

    useEffect(() => {
        if (currentToken) {
            setLeftDays(leftDaysFromNow(currentToken.premiumEndTime))
            return
        }
        setLeftDays(0)
    },[currentToken])


    return (
        <Row height="58px" mt="48px" px="20px" className='w100 jc-sb' background="rgba(255, 255, 255, 0.1)">
            <Row>
                <Text className='fz14 oct8'>Days Left</Text>
                <Text className='fz14 oct8' mx="4px">:</Text>
                { currentToken ?
                    <Text className='fz14 oct8' style={leftDays === 0 ? {color: "#FF5252"} : {}}>{`${leftDays} Days`}</Text>
                    :
                    <Text className='fz14 oct8'>No Lancet Pass</Text>
                }
            </Row>
            <Row>
                <TokenSelect/>
            </Row>
        </Row>
    )
}