import axios, {AxiosResponse} from "axios"

export type Headers = { [key: string]: string; }

export type QuickFetch = (url: string, data?: any, withCredentials?: boolean, headers?: Headers, timeout?: number) => Promise<any>

export interface Fetch {
    (request: Request): Promise<any>

    config: Config
    get: QuickFetch
    post: QuickFetch
    put: QuickFetch
    delete: QuickFetch
}

export type Method =
    | 'get' | 'GET'
    | 'delete' | 'DELETE'
    | 'head' | 'HEAD'
    | 'options' | 'OPTIONS'
    | 'post' | 'POST'
    | 'put' | 'PUT'
    | 'patch' | 'PATCH'
    | 'purge' | 'PURGE'
    | 'link' | 'LINK'
    | 'unlink' | 'UNLINK'

export type Request = {
    url: string
    method?: Method
    data?: any
    withCredentials?: boolean
    headers?: Headers
}

export type Result = {
    code: string,
    data: any,
}

export type Config = {
    baseUrl?: string
    timeout?: number
    withCredentials?: boolean
    autoLogin: boolean
    onLogin: () => Promise<boolean>
}

export const ErrorCode = {
    SUCCESS: '000000',
    SYS_ERROR: '000001',
    MISMATCH_ARGUMENT: '000002',
    ILLEGAL_ARGUMENT: '000003',
    AUTHORIZATION_EXPIRATION: '000004',
    REQUEST_FAIL: '000005',
    REQUEST_FAIL_MSG: '000006',
    REQUEST_METHOD_NOT_SUPPORT: '000007',
    MISSING_PARAMETER: '000008',
    SERVICE_UNAVAILABLE: '000009',
    SYS_FILE_UPLOAD_ERROR: '000012',
    SYS_FILE_NOT_EXISTS: '000013',
    SYS_UPLOAD_FILE_IS_NULL: '000014',
    WITHOUT_PERMISSION: '000015',
    REQUEST_OVER_MAX_FREQUENCY: '000018',
}

export const AUTHORIZATION = 'Authorization'
export const CURRENT_WALLET = 'CURRENT_WALLET'

const getAccessToken = (): string | undefined => {
    const address = localStorage.getItem(CURRENT_WALLET)
    if (!address || !address.length) {
        return
    }
    const key = `${AUTHORIZATION}-${address}`
    const tokenString = localStorage.getItem(key)
    if (!tokenString) {
        return
    }
    let token = null
    try {
        const {refreshToken, accessToken} = JSON.parse(tokenString)
        token = accessToken
    } catch (e) {
        console.log('Parse token error')
    }
    return token
}

const fetch: Fetch = async (request: Request): Promise<any> => {
    try {
        const requestUrl = request.url
        const baseUrl = fetch.config.baseUrl
        const url = requestUrl.startsWith('http') ? requestUrl : baseUrl ? baseUrl + requestUrl : requestUrl
        const withCredentials = request.withCredentials ?? fetch.config.withCredentials
        const autoLogin = fetch.config.autoLogin
        let headers: Headers = request.headers ? request.headers : {}
        const useParams = !request.method || ['get', 'delete'].includes(request.method.toLowerCase())
        if (withCredentials) {
            const accessToken = getAccessToken()
            if (!accessToken && autoLogin) {
                const success = await fetch.config.onLogin().catch(() => {
                    return false
                })
                if (!success) {
                    return
                }
            }
        }
        if (withCredentials) {
            const accessToken = getAccessToken()
            if (!accessToken) {
                return
            }
            headers[AUTHORIZATION] = `Bearer ${accessToken}`
        }
        const response: AxiosResponse<Result> = await axios({
            url,
            headers,
            method: request.method,
            data: useParams ? undefined : request.data,
            params: useParams ? request.data : undefined,
            timeout: fetch.config.timeout,
            withCredentials
        })
        if (response.status !== 200) {
            console.error('Fetch error ===> ', response)
            return
        }
        const result = response.data
        if (!result?.code || result.code === ErrorCode.SUCCESS) {
            return result.data
        }
        if (result?.code && result.code === ErrorCode.AUTHORIZATION_EXPIRATION && fetch.config.autoLogin) {
            console.log('Authorization expiration, login & retry')
            const address = localStorage.getItem(CURRENT_WALLET)
            if (address && address.length) {
                const key = `${AUTHORIZATION}-${address}`
                localStorage.removeItem(key)
            }
            // Do login & retry fetch
            const success = await fetch.config.onLogin().catch(() => {
                return false
            })
            if (success) {
                return await fetch(request)
            }
            console.log('Login & retry failed')
            return
        }
        console.error(`Fetch failed, error code [${result.code}]`)
    } catch (e) {
        console.error(e)
    }
}

fetch.config = {
    timeout: 60000,
    withCredentials: true,
    autoLogin: true,
    onLogin: async () => true
}

fetch.get = async (url: string, data?: any, withCredentials?: boolean, headers?: Headers): Promise<any> => {
    return await fetch({url, method: 'GET', data, withCredentials, headers})
}

fetch.post = async (url: string, data?: any, withCredentials?: boolean, headers?: Headers): Promise<any> => {
    return await fetch({url, method: 'POST', data, withCredentials, headers})
}

fetch.put = async (url: string, data?: any, withCredentials?: boolean, headers?: Headers): Promise<any> => {
    return await fetch({url, method: 'GET', data, withCredentials, headers})
}

fetch.delete = async (url: string, data?: any, withCredentials?: boolean, headers?: Headers): Promise<any> => {
    return await fetch({url, method: 'DELETE', data, withCredentials, headers})
}

export default fetch