import styled, { DefaultTheme } from 'styled-components'
import { space, typography, layout } from 'styled-system'
import { TextProps } from './types'



const Text = styled.div<TextProps>`
  font-family: 'JetBrains Mono';
  font-weight: 700;
  line-height: 1.5;
  color: white;
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${({ ellipsis }) =>
    ellipsis &&
    `white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}
  ${space}
  ${typography}
  ${layout}
  cursor: default;
`

Text.defaultProps = {
  color: '#fff',
  small: false,
  ellipsis: false
}

export default Text
