import React, { HTMLAttributes, ImgHTMLAttributes, ReactElement } from 'react'

import styled from 'styled-components'

interface imageProps extends ImgHTMLAttributes<HTMLImageElement> {
  width?: number
  height?: number
  src: string
}

const StyleImage = styled.img<imageProps>`
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
`
export const Image: React.FC<imageProps> = ({ height = 18, width = 18, src, ...props }) => {
  return <StyleImage height={height} width={width} src={src} {...props} />
}
