import {img_avator1, img_avator2, img_avator3, img_avator4, img_discord, img_gitbook, img_mac, img_twitter, img_windows, img_youtube} from '../assets/img'
import {svg_step2_desc_0, svg_step2_desc_1, svg_step2_desc_2} from '../assets/svg'

export const NAV_TABS = ['Home', 'Membership', 'License', 'Partner']
export const ANCHORS = ['Lancet', 'Features', 'Team', 'FAQ']
export const TEAM_NAME = ['Asen', 'Nene', 'Tom', 'Anduin']


export const SLOGAN = `
LANCET
LIGHT IT UP ON CHAIN
`
export const lancet_slogen = `
Fast, swift, and 
effortless - that's Lancet.
`
// page content
export const home_page1_left_1: string = `
`
export const home_page1_left_2: string = `
`

export const membership_left_1: string = ``
export const membership_left_2: string = ``
export const membership_right_1: string = `
`
export const membership_right_2: string = `
`
export const license_left_1: string = `
`
export const license_left_2: string = `
`
export const license_right_1: string = `
`
export const license_right_2: string = `
`

export const Features = [
    {
        img: svg_step2_desc_0,
        title: 'Hull',
        desc: 'A robust system with stable and lightning-fast performance:',
        floats: [
            {title: 'Block 0', top: '24px', left: '0px'},
            {title: 'Live Signature Drop', top: '14px', left: '158px'},
            {title: '3s Listings', top: '20px', left: '400px'},
            {title: 'Contract Mint', top: '40px', left: '68px'},
            {title: 'Built with Rust', top: '44px', left: '318px'},
        ]
    },
    {
        img: svg_step2_desc_1,
        title: 'Firearms',
        desc: 'UI/UX optimized toolkit to assist you with high-level, precise, and EFFORTLESS NFT trading',
        floats: [
            {title: 'Batch Task Manager', top: '24px', left: '0px'},
            {title: 'Probability Targeting', top: '14px', left: '158px'},
            {title: 'Multi-market Listing Support', top: '20px', left: '400px'},
            {title: 'One-click Quick Task', top: '46px', left: '52px'},
            {title: 'Auto Txn Speed Up', top: '44px', left: '318px'},
        ]
    },
    {
        img: svg_step2_desc_2,
        title: 'Cruise Control',
        desc: 'The most vigilant lancers never take their eyes off the mission:',
        floats: [
            {title: 'One of the best and most efficient private nodes', top: '14px', left: '0px'},
            {title: '24/7 Online Development Team', top: '44px', left: '72px'},
        ]
    },

]

export const TeamInfo = [
    {
        avator: img_avator1, name: 'Asen', job: 'Founder', twitter: 'https://twitter.com/Asennn_1',
        desc: [
            'Founder of Lancet/top Alpha community TBP (FP once 5eth)',
            'CEO of an office with 20+ offline employees',
            'Core Team of 3two Group',
            '300ETH earnings from trading alone in 2022',
            'Top-tier NFT trader with unique models',
        ]
    },
    {
        avator: img_avator2, name: 'NeNe', job: `Founder`, twitter: 'https://twitter.com/neneeee02',
        desc: [
            'Founder of Lancet & Pewee Tools',
            `Fordham University - Master's Degree, Quantitative Finance, Cohort 12`,
            'Ex-fulltime financial engineer in NYC at a fintech company',

        ]
    },
    {
        avator: img_avator3, name: 'Tom', job: 'Tech Manager', twitter: 'https://twitter.com/tom77799',
        desc: [
            `Master's degree in information security`,
            `Ex-security expert at Hashkey Capital 5-years of experience in cryptography, on-chain data & contract`,

        ]
    },
    {
        avator: img_avator4, name: 'Anduin', job: 'Developer', twitter: 'https://twitter.com/0x_Anduin',
        desc: [
            'Senior blockchain developer',
            'Strong coding skill set in Rust and Golang',
            'Enriched experience in Dex, GameFi and Defi protocols'
        ]
    },
]

export const FAQList = [
    {
        id: 1,
        title: '1.What is Lancet?',
        content: 'Lancet is a mintbot developed by a team of TOP NFT traders. It is highly enhanced with all the relevant and up-to-date modifications.'
    },
    {id: 2, title: '2.How can I get access to the bot?', content: `First, you need to buy a Lancet pass then you're eligible to buy the official membership on the Lancet website.`},
    {id: 3, title: '3.What operating system does Lancet bot support?', content: 'Windows and Mac/OS.'},
    {id: 4, title: '4.How can I contact the team?', content: 'Join our discord or DM us on Twitter.'}
]

export const ONE_DAY_SECONDS = 86400
export const DURATION = 1500

export const ENV_DEV = process.env.NODE_ENV === "development"
export const API_BASE_URL = ''

const PASSCARD_CONTRACT_DEV = '0x2ad283A0bc713cd45887891f95b3275e85f9Bc75'
const STAKING_1_MEMBERSHIP_1_CONTRACT_DEV = '0xdC5e4487Cc601f9A362e106a3EEF2b9372217809'
const STAKING_2_CONTRACT_DEV = '0xb77FB4bc11F436098C86C8ef3aa27111c5383B70'
const MEMBERSHIP_2_CONTRACT_DEV = '0x8BFBC56Cbb82320dF1025F8007ad831601ED3957'

const PASSCARD_CONTRACT_PROD = '0x00000000000881D280439988781F743E8cDd1fdF'
const STAKING_1_MEMBERSHIP_1_CONTRACT_PROD = '0x0000000000707fE5DE2b5dA0EEFB2E2cAaD9270F'
const STAKING_2_CONTRACT_PROD = '0x000000000b61e96f4a8fd054b57d7fc43cdd2438'
const MEMBERSHIP_2_CONTRACT_PROD = '0x000000000924e6d61d448a5cb2985d20213abc78'

export const PASSCARD_CONTRACT = ENV_DEV ? PASSCARD_CONTRACT_DEV : PASSCARD_CONTRACT_PROD
export const STAKING_1_MEMBERSHIP_1_CONTRACT = ENV_DEV ? STAKING_1_MEMBERSHIP_1_CONTRACT_DEV : STAKING_1_MEMBERSHIP_1_CONTRACT_PROD
export const STAKING_2_CONTRACT = ENV_DEV ? STAKING_2_CONTRACT_DEV : STAKING_2_CONTRACT_PROD
export const MEMBERSHIP_2_CONTRACT = ENV_DEV ? MEMBERSHIP_2_CONTRACT_DEV : MEMBERSHIP_2_CONTRACT_PROD

export const GAS_LIMIT = '210000'

export const CHAIN_ID = ENV_DEV ? 5 : 1
export const FREE_STAKE_START_TIMESTAMP = new Date('2023-04-27T14:00:00.000Z').getTime()
export const FREE_STAKE_END_TIMESTAMP = new Date('2023-07-10T11:00:00.000Z').getTime()

export const MINT_PHASES = {
    // Note: Use utc time
    phaseOne: {
        // mint phase 1
        startTime: '2023-04-05T16:00:00.000Z',
        endTime: '2023-04-05T16:30:00.000Z',
        content: 'Phase 1: Guaranteed Mint',
        price: 0.175
    },
    phaseTwo: {
        // mint phase 2
        startTime: '2023-04-05T16:30:00.000Z',
        endTime: '2023-04-05T17:30:00.000Z',
        content: 'Phase 2: WL Mint (fcfs)',
        price: 0.175
    }
}
export const DOWNLOADS = [
    {name: 'Windows', img: img_windows, url: 'https://download.lancet.pro/Lancet-amd64-installer.exe'},
    {name: 'MacOS', img: img_mac, url: 'https://download.lancet.pro/Lancet-darwin-universal.zip'},
    {name: 'M1', img: img_mac, url: 'https://download.lancet.pro/Lancet-darwin-universal.zip'},
]

export const BUY_PASS_ON_OPENSEA = {
    name: 'Buy Pass On Opensea',
    url: 'https://opensea.io/collection/lancetbot-official'
}

export const SUPPORT_LINK = [
   // {img: img_discord, url: 'https://discord.gg/lancetbot'},
    {img: img_twitter, url: 'https://twitter.com/Lancetbot'},
    {img: img_youtube, url: 'https://www.youtube.com/channel/UCzwJDAUVwYS2Kv7eWuudlYw'},
    {img: img_gitbook, url: 'https://lancetbot.gitbook.io/lancet/'},
]

// 30 days origin price
export const DAYS_30_PRICE: number = 0.2
