import React, {useEffect, useState} from 'react'
import {svg_anchor, svg_anchor_active, svg_down} from '../../assets/svg'
import {Box, Image, Text} from '../../packages'
import {Col, Row, Support} from '../../components'
import ReactFullpage from '@fullpage/react-fullpage'
import {Circles, Screen1, Screen2, Screen3, Screen4} from './components'
import {ANCHORS, DURATION, TEAM_NAME} from '../../config'
import {anchorsIndexAction, selectAnchorsIndex, selectTeamActive, teamActiveAction} from '../../store/reducer'
import {useAppDispatch, useAppSelector} from '../../store/hooks'


export const Home = () => {
    const [activeIdx, setActiveIdx] = useState<number>(0)
    const [dire, setDire] = useState<string>('')
    const [pageApi, setFullpageApi] = useState<any>(null)
    const dispatch = useAppDispatch()
    const anchorsIndex = useAppSelector(selectAnchorsIndex)

    useEffect(() => {
        function handleKey(e: any) {            
            if(e.keyCode === 9) { //禁用Tab按键
                e.preventDefault()
            }
        }
        window.addEventListener("keydown", handleKey)
        return () => window.removeEventListener("keydown", handleKey)
    },[])

    useEffect(() => {
        if(pageApi) {
            if(anchorsIndex !== 0) {
                onChnageAnchor(0)
            }
        }
    },[pageApi])    

    const onChnageAnchor = (idx: number) => {
        pageApi.moveTo(idx+1)
    }
    return (
        <Box className="">
            <ReactFullpage
                scrollingSpeed = {DURATION} 
                onLeave={(origin, destination, direction) => {
                    setActiveIdx(destination.index)
                    dispatch(anchorsIndexAction(destination.index))
                    setDire(direction)
                }}
                render={({ state, fullpageApi }) => {
                    setFullpageApi(fullpageApi)
                    return (
                        <ReactFullpage.Wrapper>
                            <div className="section">
                                <Screen1 direction={dire} activeIdx={activeIdx}/>
                            </div>
                            <div className="section">
                                <Screen2 direction={dire} screenIdx={activeIdx}/>
                            </div>
                            <div className="section">
                                <Screen3 direction={dire} screenIdx={activeIdx}/>
                            </div>
                            <div className="section">
                                <Screen4 direction={dire} screenIdx={activeIdx}/>
                            </div>
                        </ReactFullpage.Wrapper>
                    )
                }}
            />
            <Support/>
            <Circles activeIdx={activeIdx} direction={dire} />
            <Box className='absolute' style={{right: 70, bottom: '48px'}}>
                <Anchors 
                    activeIdx={activeIdx} 
                    onAnchor={onChnageAnchor}
                    onTeamName={(i: number) => {
                        dispatch(teamActiveAction({
                            idx: i,
                            isDetail: true
                        }))
                    }}
                />
            </Box>
        </Box>
    )
}

interface iAnchors {
    activeIdx: number
    onAnchor: (a: number) => void
    onTeamName: (idx: number) => void
}
export const Anchors:React.FC<iAnchors> = ({ activeIdx, onAnchor, onTeamName }) => {

    const teamActive = useAppSelector(selectTeamActive)


    return (
        <Col className='ai-end'>
            <Col height="260px">
                {
                    activeIdx === 2 && teamActive.isDetail ? (<>{
                        TEAM_NAME.map((a: string, index: number) => (
                            <Row mt="50px" className='click jc-end' key={a} onClick={() => onTeamName(index)}>
                                <span className="white fw700  fz10 mr30" style={{ opacity: teamActive.idx === index ? 0.8 : 0.6 }}>{a}</span>
                                <Image src={teamActive.idx === index ? svg_anchor_active : svg_anchor} height={18} width={18}/>
                            </Row>
                        ))
                    }</>): (<>{
                        ANCHORS.map((a: string, index: number) => (
                            <Row mt="50px" className='click jc-end' key={a} onClick={() => onAnchor(index)}>
                                <span className="white fw700  fz10 mr30" style={{ opacity: activeIdx === index ? 0.8 : 0.6 }}>{a}</span>
                                <Image src={activeIdx === index ? svg_anchor_active : svg_anchor} height={18} width={18}/>
                            </Row>
                        ))
                    }</>)
                }
            </Col>
            <Image src={svg_down} width={10} height={188} className="mt58" style={{marginRight: '4.5px'}}/>
        </Col>
    )
}



export const Slogen = () => {
    return (
        <Box className='absolute' ml="70px" style={{ bottom: '70px' }}>
            <Text className='fz12 oct6 linear_gradient linear_gray'>Lancet is the Fastest</Text>
            <Text className='fz12 oct6 linear_gradient linear_gray'>bot to make u earn</Text>
        </Box>
    )
}