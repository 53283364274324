import React from 'react'
import {svg_circle1, svg_circle2, svg_circle3, svg_circle4} from '../../assets/svg'
import {Box, Image, Text,} from '../../packages'
import styled from 'styled-components'
import {openWindow} from '../../util/utils'
import {animated, useSpring} from '@react-spring/web'
import {DURATION, SUPPORT_LINK} from '../../config'
import {useAppSelector} from "../../store/hooks"
import {selectMintTimeEnded} from "../../store/reducer"

const CircleContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 0;    
    margin: auto;   

    left: 0;
    top: -88px;

    height: 664px;
    width: 664px;
`

const SupportContaienr = styled(Box)`
    position: fixed; 
    bottom: 48px;
    left: 70px;
    width: 152px
`
const SupportSpan = styled.span`
    background: linear-gradient(92.16deg, #ECECEC -2.04%, #B3B2CC 99.99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 164%;
    margin-left: 8px;
    color: #fff;
    margin-top: 4px;
`
export const Left = styled(Box)`
    position: fixed; 
    bottom: 310px;
    left: 70px;
`
export const Right = styled(Box)`
    position: fixed; 
    top: 200px;
    right: 70px;
`
export const Circle = () => {
    return (
        <div className='circle_container hide'>
            <div className="circle_bg_start_no_animate"/>
            <img src={svg_circle1} alt="" className="circle1_rotate circle1_init "/>
            <img src={svg_circle2} alt="" className="circle2_rotate circle2_init"/>
            <img src={svg_circle3} alt="" className="circle3_rotate circle3_init"/>
            <img src={svg_circle4} alt="" className="circle4_rotate circle4_init" />               
        </div>
    )
}

export const Support = () => {
    return (
        <SupportContaienr className='fx-row ai-ct jc-sb'>
            {
                SUPPORT_LINK.map((item:any) => 
                <Image src={item.img} width={26} height={26} className="click" onClick={() => openWindow(item.url)}/>)
            }
        </SupportContaienr>
    )
}

interface iContainerBg {
    // left: React.ReactNode
    // right: React.ReactNode
    left1?: string
    left2?: string
    right1?: string
    right2?: string
}
export const ContainerBg:React.FC<iContainerBg> = ({
    // left,
    // right,
    left1,
    left2,
    right1,
    right2
}) => {
    const mintTimeEnded = useAppSelector(selectMintTimeEnded)

    const [springs0, api0] = useSpring(() => ({ from: { opacity: 0 }, to: { opacity: 1 }, config: { duration: DURATION } }))
    const [springs1, api1] = useSpring(() => ({ from: { y: -50, opacity: 0 }, to: { y: 0, opacity: 1 }, config: { duration: DURATION } }))
    const [springs2, api2] = useSpring(() => ({ from: { y: -80, opacity: 0 }, to: { y: 0, opacity: 1 }, config: { duration: DURATION}, delay: DURATION - 500  }))
    return (
        <Box>
            <Circle/>
            <animated.div style={{...springs0}}>
                <Support/>
            </animated.div>
                { left1 && left2 && !mintTimeEnded && (
                    <animated.div 
                        style={{
                            ...springs1,
                            position: 'fixed',
                            bottom: '310px',
                            left: '70px'
                        }} 
                        className='fx-col'
                    >
                        <Text className='fz10 text_linear pre_wrap lh32'>{ left1 }</Text>
                        <Text className='fz10 text_linear pre_wrap lh32'>{ left2 }</Text>
                    </animated.div>
                )}
                { right1 && right2 && (
                    <animated.div 
                        style={{
                            ...springs2,
                            position: 'fixed',
                            top: '200px',
                            right: '70px'
                        }} className='fx-col'>
                        <Text className='fz10 text_linear pre_wrap lh32' >{ right1 }</Text>
                        <Text className='fz10 text_linear pre_wrap lh32' mt="16px">{ right2 }</Text>
                    </animated.div>
                )}
        </Box>
    )
}