import React, { useEffect, useState } from 'react'
import { svg_jagged, svg_dashed } from '../../../assets/svg'
import { img_avator, img_twitter} from '../../../assets/img'
import { Box, Text, Image, Grid } from '../../../packages'
import { Row, Col, Left, Support, ColorTitle } from '../../../components'
import { TeamInfo } from '../../../config'
import styled from 'styled-components'
import { openWindow } from '../../../util/utils'
import { useSpring, animated } from '@react-spring/web'
import { sleep } from '../../../util/utils'
import { selectTeamActive, teamActiveAction } from '../../../store/reducer'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { DURATION } from '../../../config'

const BackHome = styled(Box)<{isNext: boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 40px;
    background: ${({isNext}) => isNext ? 
    'linear-gradient(92.16deg, #ECECEC -2.04%, #B3B2CC 99.99%)' :
    'linear-gradient(90deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%)'
    };
    border: 1px solid rgba(242, 207, 217, 0.4);
    font-size: 14px;
    color: ${({isNext}) => isNext ? 'rgb(67,66,72)' : '#FFFFFF'}; 
    cursor: pointer;
`
const Nene = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 116px;
    height: 24px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(92.16deg, #ECB9D8 -2.04%, #5D63F3 99.99%);
    border: 1px solid rgba(242, 207, 217, 0.3);
`

interface iAvator {
    avator: string
    name: string
    job: string
    twitter: string
    desc: string[]
    onChange: (name: string, idx: number) => void
    idx: number
}

export const Screen3:React.FC<{direction: string, screenIdx: number}> = ({direction, screenIdx}) => {
    const [springs0, api0] = useSpring(() => ({ from: { y: -150, opacity: 0 }, to: { y: 0, opacity: 1 }, config: { duration: DURATION } }))

    const [springs, api] = useSpring(() => ({ 
        from: { x: 0, display: 'block' }, 
        // config: { duration: 100 },
        // OnRest: () => {
        //     console.log('OnRest1')
        // }
    }))
    const [springs1, api1] = useSpring(() => ({ from: { x: 1000, display: 'none', } }))
    const [title, setTitle] = useState<string>('TEAM')
    const [activeIdx, setActiveIdx] = useState<number>(0)
    const dispatch = useAppDispatch()
    const teamActive = useAppSelector(selectTeamActive)

    
    const onChange = (n: string,idx: number) => {     
        // console.log('onChange', n)   
        // console.log('idx', idx)   
        if(n === 'back') {
            dispatch(teamActiveAction({ isDetail: false, idx: 0 }))
            setTitle('TEAM')
            api.start({ x: 0, display: 'block' })
            api1.start({ x: 1000, display: 'none',})
        }else if(n === 'next') {
            const _idx = idx === 3 ? 0 : idx+1
            dispatch(teamActiveAction({ isDetail: true, idx: _idx }))
            setActiveIdx(_idx)
            // console.log('next', idx)      
        }else {
            dispatch(teamActiveAction({ isDetail: true, idx }))
            setActiveIdx(idx)
            setTitle(n)
            api.start({to: { x: -500, display: 'none' },})
            api1.start({ x: 1000, display: 'block',})
            setTimeout(() => {
                api1.start({ x: 0, display: 'block',})
            },100)          
        }    
    }
    useEffect(() => {
        setActiveIdx(teamActive.idx)
    },[teamActive.idx])

    useEffect(() => {
        if(title !== 'TEAM') {
            setTitle(`TEAM — ${TeamInfo[activeIdx].name}`)
        }
    },[activeIdx])

    useEffect(() => {
        if(direction === 'up') {
            if(screenIdx === 2) {
                api0.start({ from: { y: 150, opacity: 0 }, to: { y: 0, opacity: 1 }, config: { duration: DURATION } })
            }
        }   
        if(direction === 'down') {
            if(screenIdx === 3) {
                api0.start({ from: { opacity: 1 }, to: { opacity: 0 }, config: { duration: 500 } })
            }
        }
    },[direction, screenIdx])

    return (
        <animated.div className='fx-col ai-ct' style={{ ...springs0 }}>
            <Col className='ai-ct' height="126px">
                <ColorTitle title={title}/>
                <span className='oct4 white fw700 linear_gradient linear_gray fz12 mt24 pre_wrap'>{
                    `We’re here to navigate and help you explore the fluctuating markets safely and
                    elevate your trading experiences to the next level.`
                }</span>
            </Col>

            <Box height="400px" className='hide-y w100 center center'>
                <animated.div className="" style={{ ...springs }}>
                    <Row width="336px" height="400px" className='wrap jc-sb'>
                        { TeamInfo.map((team: any, idx: number) => ( <Avator key={team.name} {...team} idx={idx} onChange={onChange}/> )) }
                    </Row>
                </animated.div>

                <animated.div className="" style={{ ...springs1 }}>
                    <AvatorDetail 
                        {...TeamInfo[activeIdx]}
                        onChange={onChange}
                        idx={activeIdx}
                    />
                </animated.div>
            </Box>           
        </animated.div>
    )
}

export const Avator:React.FC<iAvator> = ({
    avator,
    name,
    job,
    twitter,
    desc,
    onChange,
    idx
}) => { 
    const handleClick = () => {
        onChange(`TEAM — ${name}`, idx)
    }

    return (
        <Box className="fx-col ai-ct" mt="32px">
            <Image src={avator} alt="" height={90} width={90} className="click" onClick={handleClick}/>
            <Row mt="12px" mb="8px">
                <Text className='fz12 oct8'>{name}</Text>
                <Text className='fz12 linear_color linear_gradient oct6' ml="8px">{job}</Text>
            </Row>
            <Image src={svg_dashed} alt="" height={8} width={128}/>            
            <Image src={img_twitter} alt="" height={20} width={20} className="click mt10" onClick={() => openWindow(twitter)}/>            
        </Box>
    )
}


export const AvatorDetail:React.FC<iAvator> = ({ 
    avator,
    name,
    job,
    twitter,
    desc,
    onChange,
    idx
 }) => {
    const onBack = () => {
        onChange('back', idx)
    }
    const onNext = () => {
        onChange('next', idx)
    }
    return (
        <Col className="ai-ct ">
            <Row mt="32px">
                <Image src={avator} alt="" height={128} width={128}/>
                <Col className='ai-start' ml="16px">
                    <Row>
                        <Row mr='16px'>
                            <Text className='fz12 oct8'>{name}</Text>
                            <Text className='fz12 linear_color linear_gradient' ml="8px">{job}</Text>
                        </Row>
                        <Image src={img_twitter} height={20} width={20} className="click" onClick={() => openWindow(twitter)}/>                    
                    </Row>
                    { 
                    // name === 'NeNe' && (
                    //     <Nene mt="8px">
                    //         <Text className='fz12 fw700 white oct8'>Woman in Web3</Text>
                    //     </Nene>
                    // )
                    }
                </Col>
            </Row>
            <Col mt="12px" height="146px">
                {
                     desc.map((d: string) => (
                        <li 
                            className='white fw700 fz12 oct6' 
                            key={d} 
                            style={{textAlign: 'center', lineHeight: '240%' }}>{d}</li>
                     ))
                }
            </Col>   
            <Row mt="40px">
                <BackHome isNext={false} onClick={onBack}>Back</BackHome>
                <BackHome isNext={true} ml="40px" onClick={onNext}>Next</BackHome>
            </Row>    
        </Col>
    )
}
