import React, {useEffect} from 'react'
import {ColorTitle, ContainerBg, LeftDays} from '../../components'
import {Box} from '../../packages'
import {DURATION, license_left_1, license_left_2, license_right_1, license_right_2} from '../../config'
import {useAppSelector} from '@/store/hooks'
import {useNavigate} from 'react-router-dom'
import {animated, useSpring} from '@react-spring/web'
import {selectAddress} from "@/store/reducer"
import {LicenseStatus} from "./LicenseStatus";

export const License = () => {

    const [springs0, api0] = useSpring(() => ({from: {opacity: 0}, to: {opacity: 1}, config: {duration: DURATION + 500}}))

    const navigator = useNavigate()
    const address = useAppSelector(selectAddress)

    useEffect(() => {
        if (!address) {
            navigator('/home')
        }
    }, [address])

    return (
        <div className="h100">

            <ContainerBg
                left1={license_left_1}
                left2={license_left_2}
                right1={license_right_1}
                right2={license_right_2}
            />
            <animated.div className='center w100 h100' style={{...springs0}}>
                <Box className='fx-col ai-ct' width="50%" minWidth="500px" maxWidth="600px">
                    <ColorTitle title="LICENSE"/>
                    <LeftDays/>
                    <LicenseStatus/>
                </Box>
            </animated.div>
        </div>
    )
}