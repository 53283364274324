import React, { useEffect } from 'react'
import { Collapse } from 'antd'
import { FAQList, DURATION } from '../../../config'
import { Text } from '../../../packages'
import { useSpring, animated } from '@react-spring/web'
import { Accordion } from '../../../components'

const { Panel } = Collapse

export const Screen4:React.FC<{direction: string, screenIdx: number}> = ({direction, screenIdx}) => {
    const [springs0, api0] = useSpring(() => ({ from: { y: -150, opacity: 0 }, to: { y: 0, opacity: 1 }, config: { duration: DURATION } }))

    useEffect(() => {
        if(direction === 'up') {
            if(screenIdx === 3) {
                api0.start({ from: { y: 150, opacity: 0 }, to: { y: 0, opacity: 1 }, config: { duration: DURATION } })
            }
        }   
        if(direction === 'down') {
            if(screenIdx === 4) {
                api0.start({ from: { opacity: 1 }, to: { opacity: 0 }, config: { duration: 500 } })
            }
        }
    },[direction, screenIdx])

    return (
        <animated.div className='step4'>
            <Text className='linear_color oct8 fz24 linear_gradient' mb="32px">FAQ</Text>
            <Accordion/>
        </animated.div>
    )
}