import React, { useState, useRef, useCallback, useEffect } from 'react'
import moment from 'moment'

const calculateDuration = (et: number) => moment.duration(Math.max(et - Math.floor(Date.now() / 1000), 0), 'seconds')

export const useCountdown = (eventTime: number, interval: number) => {
    const [duration, setDuration] = useState(calculateDuration(eventTime))
    const timerRef: any = useRef(0)

    const timerCallback = useCallback(() => {
      setDuration(calculateDuration(eventTime))
    }, [eventTime])
  
    useEffect(() => {
      timerRef.current = setInterval(timerCallback, interval)
      return () => {
        clearInterval(timerRef.current)
      }
    }, [eventTime])
    return duration
}