import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import homeReducer from './reducer/homeReducer'
import licenseReducer from './reducer/licenseReducer'
import membershipReducer from './reducer/membershipReducer'
import walletReducer from './reducer/walletReducer'


export const store = configureStore({
  reducer: {
    homeReducer,
    licenseReducer,
    membershipReducer,
    walletReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
