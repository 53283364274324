import React, {useEffect, useState} from 'react'
import {Box, Grid, Image, Text} from '../../../packages'
import {Button, ColorText, ColorTitle, ErrorNotification, LeftDays, LinearBox, MaskBg, Row, SuccessNotification, Tip, WarningNotification} from '../../../components'
import styled from 'styled-components'
import {img_eth} from '../../../assets/img'
import {openWindow} from '../../../util/utils'
import {BUY_PASS_ON_OPENSEA} from '../../../config'
import {PledgeModal} from './PledgeModal'
import {selectAddress, selectCurrentToken, selectDiscount, selectMembership2Contract, selectMembership2ContractSigner, selectProductList, selectStakedContract, selectStakeTokenId, selectStaking1Membership1Contract, selectStaking1Membership1ContractSigner, selectTokens} from '../../../store/reducer'
import {useAppSelector} from '../../../store/hooks'
import {accDiv, accSub, multi} from '../../../util/math'
import {formatEther} from "@ethersproject/units";
import {premium} from "../../../request/Contract";
import axios from 'axios'
import {BigNumber} from "@ethersproject/bignumber";

const PriceItem = styled(Box)<{active: boolean}>`
    border-image: ${({active}) => active ? 
    'linear-gradient(#FEB2C9, #4353F8) 30' : 'rgba(242, 207, 217, 0.3)'};
    border-color: rgba(242, 207, 217, 0.3);
    border-width: 1px;
    border-style: solid;
    background: ${({active}) => active ? 
    'linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)' : 
    'linear-gradient(90deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%)'};
    &:hover {
        background:  linear-gradient(90deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%);
        border-image: linear-gradient(#FEB2C9, #4353F8) 30;
    }
    
    height: 192px;
    min-width: 166px;
    cursor: pointer;
`
const DayU = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    max-width: 92px;
    background: rgba(255, 255, 255, 0.05);
`

interface iProducts{
    id: number
    price: any
    originalPrice: number
}

export interface iPledge {
    isOpen: boolean
    type: string
}
const initPledge = {
    isOpen: false,
    type: ''
}

export const Purchase:React.FC = () => {
    const [activePrice, setActivePrice] = useState<number>(-1)
    const [ethPrice, setETHPrice] = useState<number>(0)
    const [pledge, setPledge] = useState<iPledge>(initPledge)
    const [maxDay, setMaxDay] = useState<number>(0)
    const [buying, setBuying] = useState<boolean>(false)

    const address = useAppSelector(selectAddress)
    const currentToken = useAppSelector(selectCurrentToken)
    const productList = useAppSelector(selectProductList)
    const staking1Membership1Contract = useAppSelector(selectStaking1Membership1Contract)
    const staking1Membership1ContractSigner = useAppSelector(selectStaking1Membership1ContractSigner)
    const membership2ContractSigner = useAppSelector(selectMembership2ContractSigner)
    const discount = useAppSelector(selectDiscount)

    useEffect(() => {
        fetch().then(_r => {})
    },[])

    const fetch = async() => {
        const url = process.env.NODE_ENV === "development" ?
        '/api/data/price?fsym=ETH&tsyms=USD' :
        'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD'
        const res:any = await axios.get(url)
        if(res.status === 200) {
            setETHPrice(res.data.USD)
        }
    }

    const onBuy = () => {
        console.log(' productList[activePrice]',  productList[activePrice])
        const { membershipDays, membershipOriginPrice, membershipHolderPrice, membershipPrice, onSale } = productList[activePrice]
        if (!onSale) {
            WarningNotification('The current option cannot be purchased.')
            return
        }
        setBuying(true)
        const days = membershipDays.toNumber()
        premium(membership2ContractSigner, days, currentToken?.tokenId, finalPrice(membershipHolderPrice, membershipPrice), () => {
            SuccessNotification(`Congratulations! You have successfully purchased ${days} days membership.`)
            setBuying(false)
        }, (e: Error) => {
            if (e.message.includes('ACTION_REJECTED')) {
                WarningNotification('Action rejected.')
                setBuying(false)
                return
            }
            ErrorNotification('Membership purchase failed. Please retry.')
            setBuying(false)
        })
    }

    const onClose = () => {
        setPledge(initPledge)
    }

    const xU1Day = (days: number, price: any) => {
        return ethPrice !== 0 ? accDiv(multi(ethPrice, Number(formatEther(price))), days).toFixed(2) : 0
    }

    const finalPrice = (membershipHolderPrice: BigNumber, membershipPrice: BigNumber): BigNumber => {
        return currentToken?.tokenId && currentToken?.tokenId != 10000 ? membershipHolderPrice : membershipPrice
    }

    return (
        <>
            <LeftDays/>
            <MaskBg h={338}>
                <Grid className="w100" gridTemplateColumns={`repeat(4, 1fr)`}  gridGap={['2px','8px','16px','40px']}>
                    {
                        productList.map((item: any, index: number) => (
                            <PriceCard
                                onSale={item.onSale}
                                originalPrice={Number.parseFloat(formatEther(item.membershipOriginPrice))}
                                key={item.membershipDays.toNumber()}
                                id={item.membershipDays.toNumber()}
                                price={formatEther(finalPrice(item.membershipHolderPrice, item.membershipPrice))}
                                onSelect={() => setActivePrice(activePrice === index ? -1 : index)}
                                active={activePrice === index}
                                index={index}
                                u={
                                    xU1Day(item.membershipDays.toNumber(), finalPrice(item.membershipHolderPrice, item.membershipPrice))
                                }
                            />
                        ))
                    }
                </Grid>
                <Box width="70%" className=''>
                    <Row mt="40px" className='w100 jc-sb center'>
                        <Grid className="w100" gridTemplateColumns={['1fr 1fr']} gridGap="16px">
                            <Button
                                width="100%"
                                maxWidth="240px"
                                onClick={onBuy}
                                name="BUY"
                                height="40px"
                                type={'dark'}
                                loading={buying}/>
                            <Tip title="Coming soon" placement="topRight">
                                <Button
                                    width="100%"
                                    maxWidth="240px"
                                    onClick={() => null }
                                    name="STAKING"
                                    height="40px"
                                    type={'disabled'}
                                    loading={false}/>
                            </Tip>
                        </Grid>
                    </Row>
                </Box>
            </MaskBg>
            <PledgeModal account={ address } maxDay={maxDay} token={currentToken} contract={staking1Membership1Contract} membershipPrice={productList[activePrice]?.membershipPrice} contractSigner={staking1Membership1ContractSigner} pledge={pledge} onClose={onClose}/>
        </>
    )
}


interface iPriceCard {
    onSelect: () => void
    active: boolean
    index: number
    u: number | string
    onSale: boolean
}
const PriceCard:React.FC<iProducts & iPriceCard> = ({
    id,
    price,
    onSelect,
    active,
    originalPrice,
    index,
    onSale,
    u
}) => {
    return (
        <PriceItem style={{position: 'relative'}} active={onSale ? active : false} key={id} padding={['4px','4px','4px','24px']} onClick={onSale ? () => onSelect() : () => null }>
            <div style={{position: 'absolute', top: 5, right: 5}}>
                { onSale ?
                    <ColorText title={'On Sale'} fz={'8px'}/> :
                    <Text className='fz8 oct3 fw300'>Sold Out</Text>
                }
            </div>
            <Text className='fz16 oct8'>{id} Days</Text>
            <Row mt="10px">
                <Image height={28} width={28} src={img_eth}/>
                <Text className='fz32 oct8' ml="4px">{price}</Text>
            </Row>
            <DayU>
                <Text className='fz14 white oct4'>{`$${u}/Day`}</Text>
            </DayU>
            {originalPrice > price &&
            <Row mt="16px">
                <Row className='text_del'>
                    <Image height={14} width={14} src={img_eth}/>
                    <Text className='fz14 oct8'>{originalPrice}</Text>
                </Row>
                <LinearBox ml="12px" p="4px">
                    <Text className='fz12 oct5'>SAVE</Text>
                    <Text className='fz12 oct8' ml="2px">{`${multi(accDiv(Number(accSub(originalPrice, price)), originalPrice), 100).toFixed(2)}%`}</Text>
                </LinearBox>

            </Row>
            }
        </PriceItem>
    )
}