import {CoinbaseWallet, CoinbaseWalletConstructorArgs} from '@web3-react/coinbase-wallet'
import { initializeConnector } from '@web3-react/core'
import { URLS } from './chains'

export const [coinbaseWallet, hooks] = initializeConnector<CoinbaseWallet>(
  (actions) =>
    new CoinbaseWallet(<CoinbaseWalletConstructorArgs>{
        actions,
        options: {
            url: URLS[1][0],
        },
    })
)
