import {iApiToken, iFreeLicenseState, iWalletLoginParams} from './Types'
import fetch from "../components/Fetch";
import {ENV_DEV} from "@/config";

const devUrl = (url: string): string => {
    return ENV_DEV ? '/proxy' + url : url
}

export const walletLoginWithSignApi = async (params: iWalletLoginParams) => {
    const {principal, credential, nonce} = params
    const url = `/au/api/v1/login/address?principal=${principal}&credential=${credential}&nonce=${nonce}`
    return fetch.post(devUrl(url), {}, false)
}

export const getNonceApi = async (address: string, nonce: string) => {
    return fetch.get(devUrl(`/au/api/v1/captcha/nonce/${address}/${nonce}`), {}, false)
}

export const getLicenseApi = async () => {
    return fetch.get(devUrl('/mintBot/mintbotapp/v1/license/get'))
}

export const resetLicenseApi = async () => {
    return fetch.get(devUrl('/mintBot/mintbotapp/v1/license/reset'))
}

export const getMemberInfoApi = async () => {
    return fetch.get(devUrl('/bls/api/v1/member/detail'))
}

export const saveDiscordApi = async (dcId: string) => {
    return fetch.post(devUrl('/bls/api/v1/member/update/info'), {discordId: dcId})
}

export const getFreeLicenseState = async (address: string): Promise<iFreeLicenseState> => {
    return fetch.get(devUrl(`/mintBot/mintbotapp/v1/license/test/state/${address}`))
}

export const applyForFreeLicense = async (address: string, tokenId: number): Promise<boolean> => {
    return fetch.get(devUrl(`/mintBot/mintbotapp/v1/license/test/apply/${address}/${tokenId}`))
}

export const getTokens = async (address: string): Promise<iApiToken[]> => {
    return fetch.get(devUrl(`/mintBot/mintbotapp/v1/license/tokens/${address}`), undefined, false)
}