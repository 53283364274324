import React from 'react'
import {HashRouter, useRoutes} from 'react-router-dom'
import {ProviderWeb3React} from '../components'

import {Home} from '../pages/Home'
import {License} from '../pages/License'
import {Membership} from '../pages/Membership'
import {Nav} from '../pages/Nav'
import {Partner} from '../pages/Partner'

interface RouteObject {
  caseSensitive?: boolean
  children?: RouteObject[]
  element?: React.ReactNode
  index?: boolean
  path?: string
  isLink?: string
}

export const rootRouter: any = [
  {
    path: '*',
    element: <Home />
  },
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/license',
    element: <License />
  },
  {
    path: '/membership',
    element: <Membership />
  },
  {
    path: '/partner',
    element: <Partner />
  },
]

const Router = () => {
  const routes = useRoutes(rootRouter)
  return routes
}

export function AppRouter() {
  return (
    <HashRouter>
      <div className='w100 h100 bg1' >
          <div className='w100 h100 bg3' >
            <ProviderWeb3React>
              <Nav/>
            </ProviderWeb3React>
            <Router/>  
          </div>           
      </div>
    </HashRouter>
  )
}
