import React, {useState} from 'react'
import {svg_slider_down, svg_slider_up} from '../../../assets/svg'
import {Box, Image, Text} from '../../../packages'
import {Button, Col, LinearBox, Modal, OpenNotification, Row} from '../../../components'
import styled from 'styled-components'
import {Slider} from 'antd'
import {iPledge} from './Purchase'
import {iBtn, initBtn, iToken} from '../../../types'
import {GAS_LIMIT, STAKING_1_MEMBERSHIP_1_CONTRACT} from '../../../config'
import {BigNumber} from "@ethersproject/bignumber"


const Container = styled(Box)`
    padding: 40px
`
const Phase = styled(Box)<{ active: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${({active}) => active ? 'transparent' : 'linear-gradient(92.16deg, rgba(236, 236, 236, 0.15) -2.04%, rgba(179, 178, 204, 0.15) 99.99%)'};
    border: 1px solid rgba(242, 207, 217, 0.1);
    text-align: center;
    width: 200px;
`

const SliderBar = styled(Box)`
    width: 780px;
    height: 40px;
    background: rgb(44,44,48);
`
const SliderBarBuy = styled(Box)`
    height: 100%; 
    position: relative;
    background: linear-gradient(92.16deg, #ECB9D8 -2.04%, #5D63F3 99.99%);;
`
const SliderBarMax = styled(Box)`
    position: relative;
    height: 100%; 
    background: linear-gradient(92.16deg, rgba(236,185,216,0.3) -2.04%, rgba(93,99,243,0.3) 99.99%);
`
const phaseList = [
    'FOMO BOT',
    `FOMO BOT
    Wallet Alert`,
    `FOMO BOT
    Wallet Alert
    Floor Price Monitor`,
    `FOMO BOT
    Wallet Alert
    Floor Price Monitor
    Giveaway Spots`,
]
const phaseDays = ['30Days', '60Days', '90Days', '180Days']

const SliderMark = () => {
    return (
        <Col height="76px" className="absolute" style={{right: -9, top: -18, cursor: 'grab'}}>
            <Image src={svg_slider_up} height={18} width={18}/>
            <Box height="40px"/>
            <Image src={svg_slider_down} height={18} width={18}/>
        </Col>
    )
}

interface iPledgeModal {
    pledge: iPledge
    onClose: () => void
    contractSigner: any
    contract: any
    token: iToken | null
    membershipPrice: BigNumber
    maxDay: number
    account: string
}

export const PledgeModal: React.FC<iPledgeModal> = ({account, pledge, onClose, contractSigner, contract, token, membershipPrice, maxDay}) => {

    const [value, setValue] = useState<number>(0)
    const [staking, setStaking] = useState<iBtn>(initBtn)
    const [disabled, setDisabled] = useState<boolean>(false)

    const onCancel = () => {
        setStaking(initBtn)
        setValue(0)
        onClose()
    }


    const onStaking = async () => {
        setStaking({active: false, loading: true})
        // console.log('premiumPrice', premiumPrice.toNumber())
        // console.log('value', value)
        // 是否授权passcard
        const isApprovedForAll: any = await contract.isApprovedForAll(account, STAKING_1_MEMBERSHIP_1_CONTRACT)
        if (!isApprovedForAll) {
            OpenNotification('Warning', 'Not approved', 'warning')
            setStaking({active: true, loading: false})
            return
        }
        if (!token) {
            OpenNotification('Warning', 'Current token is empty', 'warning')
            return
        }
        const canStake = await contract.canStake(token.tokenId)
        if (canStake) {
            const result = await contractSigner.stake(value, token.tokenId, {
                value: membershipPrice,
                gasLimit: GAS_LIMIT,
            })
            onCancel()
        } else {
            OpenNotification('Warning', 'Can not staking', 'warning')
            setStaking({active: true, loading: false})
        }
    }

    const onSliderChange = (val: number) => {
        // console.log('value', val)
        setValue(val)
        setStaking({loading: false, active: val !== 0})
    }

    return (
        <>
            <Modal
                width={1060}
                visible={pledge.isOpen}
                onCancel={onCancel}
            >
                <Container className="fx-col ai-ct w100">
                    <Row>
                        <LinearBox px="10px" py="4px">
                            <Text className='fz20 oct8'>{`${maxDay}days`}</Text>
                        </LinearBox>
                        <Text className='linear_color linear_gradient fz20' ml="16px">Membership and Staking</Text>
                    </Row>
                    <Box className="w100 fx-row ai-end jc-sb" mt="40px">
                        {
                            phaseList.map((p: string) => (
                                <Phase active={true} py="10px" key={p}>
                                    <Text style={{whiteSpace: 'pre-wrap', lineHeight: '22px'}} className='fz12 oct8'>{p}</Text>
                                </Phase>
                            ))
                        }
                    </Box>

                    <SliderBar my="24px">
                        <SliderBarMax width={maxDay > 180 ? '100%' : `${(maxDay / 180) * 100}%`} className="">
                            <SliderBarBuy width={value > 180 ? '100%' : `${(value / maxDay) * 100}%`}>
                                <SliderMark/>
                            </SliderBarBuy>
                            <Box className='w100' height="40px" mt="-40px">
                                <Slider
                                    disabled={disabled}
                                    tooltip={{open: false}}
                                    onChange={onSliderChange}
                                    min={0}
                                    max={maxDay}/>
                            </Box>
                            {(maxDay !== 180 && maxDay !== 0) && <Text className='fz12 oct4 absolute' style={{bottom: '-20px', right: '-12px'}}>Max</Text>}
                        </SliderBarMax>
                    </SliderBar>
                    <Row className="w100 jc-sb">
                        {phaseDays.map((p: string) => <Text key={p} width="200px" className='center fz12 oct8'>{p}</Text>)}
                    </Row>
                    <Box width="70%" className=''>
                        <Row mt="32px" className='w100 jc-sb'>
                            <Button onClick={onCancel} name="Cancel" type="dark" width="240px" height="40px"/>
                            <Button
                                onClick={staking.active ? () => onStaking() : () => null}
                                name="Staking"
                                loading={staking.loading}
                                type={staking.active ? 'dark' : 'disabled'} width="240px" height="40px"/>
                        </Row>
                    </Box>

                </Container>
            </Modal>
        </>
    )
}