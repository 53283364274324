import React, { useState, useEffect } from 'react'
import { img_step2_0, img_step2_1,  img_step2_2 } from '../../../assets/img'
import { Box, Text } from '../../../packages'
import { Row, Col, DotText } from '../../../components'
import { Features } from '../../../config'
import { DURATION } from '../../../config'

import { useSpring, animated, useTrail  } from '@react-spring/web'

interface iStyle {
    zIndex: number
    filter: string
    bottom: number;
    left: number;
}

interface iFloat {
    title: string
    top: string
    left: string
}
const CONFIG = {
    mass: 2,
    tension: 170,
    friction: 12
}

export const Screen2:React.FC<{direction: string, screenIdx: number}> = ({direction, screenIdx}) => {
    const [springs0, api0] = useSpring(() => ({ from: { y: -150, opacity: 0 }, to: { y: 0, opacity: 1 }, config: { duration: DURATION } }))
    const [activeIdx, setActiveIdx] = useState<number>(0)    
    const [springs1, api1] = useSpring(() => ({zIndex: 30,filter: 'blur(0px)', bottom: 150, left: 0,delay: 0}))
    const [springs2, api2] = useSpring(() => ({zIndex: 20, filter: 'blur(2px)', bottom: 230, left: 120,delay: 0}))

    const [springs3, api3] = useSpring(() => ({zIndex: 10,filter: 'blur(4px)', bottom: 310, left: 240,delay: 0}))
    
    const handleClick1 = () => {
        setActiveIdx(0)
        api1.start({to: [{zIndex: 30},{filter: 'blur(0px)', bottom: 150, left: 0}]})
        api2.start({to: [{zIndex: 20},{filter: 'blur(2px)', bottom: 230, left: 120}]})
        api3.start({to: [{zIndex: 10},{filter: 'blur(4px)', bottom: 310, left: 240 }]})
    }
    const handleClick2 = () => {
        setActiveIdx(1)
        api2.start({to: [{ zIndex: 30},{ filter: 'blur(0px)', bottom: 150, left: 0}]})
        api1.start({to: [{ zIndex: 20},{ filter: 'blur(2px)', bottom: 230, left: 120 }]})
        api3.start({to: [{zIndex: 10},{filter: 'blur(4px)', bottom: 310, left: 240}]})
    }
    const handleClick3 = () => {
        setActiveIdx(2)
        api3.start({to: [{zIndex: 30},{filter: 'blur(0px)', bottom: 150, left: 0}]})
        api1.start({to: [{zIndex: 20},{filter: 'blur(2px)', bottom: 230, left: 120}]})
        api2.start({to: [{zIndex: 10},{filter: 'blur(4px)', bottom: 310, left: 240}]})
    }

    useEffect(() => {
        if(direction === 'up') {
            if(screenIdx === 1) {
                api0.start({ from: { y: 150, opacity: 0 }, to: { y: 0, opacity: 1 }, config: { duration: DURATION } })
            }
        }   
        if(direction === 'down') {
            if(screenIdx === 2) {
                api0.start({ from: { opacity: 1 }, to: { opacity: 0 }, config: { duration: 500 } })
            }
        }
    },[direction, screenIdx])

      
    return (
        <animated.div className="fx-row step2_img_container click" style={{ ...springs0 }} >
            <animated.img style={{ ...springs1 }}  className="click" src={img_step2_0}  alt=""  onClick={handleClick1}/>
            <animated.img style={{ ...springs2 }}  className="click" src={img_step2_1}  alt=""  onClick={handleClick2}/>
            <animated.img style={{ ...springs3 }}  className="click" src={img_step2_2}  alt=""  onClick={handleClick3}/>
            <Col className='step2_desc'>
                <Box className=' fx-row ai-end' >
                    <img src={Features[activeIdx].img} alt=""/>
                    <Col ml="12px">
                        <Text className='fz20 oct8'>{Features[activeIdx].title}</Text>
                        <Text className='fz12 oct4'>{Features[activeIdx].desc}</Text>
                    </Col>
                </Box>
                <Box height="68px" className="relative">
                    { Features[activeIdx].floats.map((t: iFloat, i: number) =>  (
                        <div 
                            className={`breathe_${i}`}
                            style={{
                                position: 'absolute',
                                left: t.left,
                                top: t.top
                            }}
                        >
                            <DotText title={t.title} />
                        </div>
                    )) }
                </Box>
            </Col>
        </animated.div>
    )
}