import React, {useEffect, useRef} from 'react'
import {svg_logo} from '../../assets/svg'
import {Box, Image} from '../../packages'
import {Row} from '../../components'
import {API_BASE_URL, FREE_STAKE_END_TIMESTAMP, FREE_STAKE_START_TIMESTAMP, MEMBERSHIP_2_CONTRACT, MINT_PHASES, NAV_TABS, PASSCARD_CONTRACT, STAKING_1_MEMBERSHIP_1_CONTRACT, STAKING_2_CONTRACT} from '../../config'
import {ConnectWallet} from './ConnectWallet'
import {useLocation, useNavigate} from 'react-router-dom'
import {Web3Provider} from "@ethersproject/providers"
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {
    CurrentTokenAction, DiscountAction,
    Membership2ContractAction,
    Membership2ContractSignerAction,
    MintTimeEndedAction,
    PasscardContractAction,
    PasscardContractSignerAction,
    ProductListAction,
    selectAddress,
    selectCurrentToken,
    selectPasscardContract,
    selectProvider,
    selectStakeIn48hState,
    selectStakeTokenId,
    selectStaking1Membership1Contract,
    selectStaking2Contract,
    selectTokens,
    StakedContractAction,
    StakeIn48hStateAction,
    StakeTokenIdAction,
    Staking1Membership1ContractAction,
    Staking1Membership1ContractSignerAction,
    Staking2ContractAction,
    Staking2ContractSignerAction,
    TokensAction,
} from '../../store/reducer'
import {createNonce, orderByField} from '../../util/utils'
import {Contract, Event} from "@ethersproject/contracts"
import {iStakeIn48hState, iToken} from "../../types";
import {STAKING_1_MEMBERSHIP_1_ABI} from "../../components/ABI/premium";
import {PASSCARD_ABI} from "../../components/ABI/passcard";
import {allPremiumTypes, holderDiscount, ownedToken} from "../../request/Contract";
import fetch, {AUTHORIZATION, CURRENT_WALLET} from "../../components/Fetch";
import {getNonceApi, getTokens, walletLoginWithSignApi} from "../../request/Api";
// @ts-ignore
import AsyncLock from 'async-lock'
import {STAKING_2_ABI} from "@/components/ABI/staking";
import {MEMBERSHIP_2_ABI} from "@/components/ABI/membership_2";

const lock = new AsyncLock()
export const Nav = () => {
    const timerRefOne: any = useRef(0)
    const timerRefTwo: any = useRef(0)
    const timerRefMintTimeEnd: any = useRef(0)
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const provider = useAppSelector(selectProvider)
    const address = useAppSelector(selectAddress)
    const passcardContract = useAppSelector(selectPasscardContract)
    const staking1Membership1Contract = useAppSelector(selectStaking1Membership1Contract)
    const staking2Contract = useAppSelector(selectStaking2Contract)
    const tokens = useAppSelector(selectTokens)
    const currentToken = useAppSelector(selectCurrentToken)
    const stakeIn48hState = useAppSelector(selectStakeIn48hState)
    const stakeTokenId = useAppSelector(selectStakeTokenId)

    const phaseTwoEndTime = new Date(MINT_PHASES.phaseTwo.endTime)

    useEffect(() => {
        const now = new Date()
        const mintTimeEnded = now >= phaseTwoEndTime
        dispatch(MintTimeEndedAction(mintTimeEnded))
        if (!mintTimeEnded) {
            timerRefMintTimeEnd.current = setTimeout(() => {
                dispatch(MintTimeEndedAction(true))
            }, phaseTwoEndTime.getTime() - now.getTime())
        }
        return () => {
            timerRefMintTimeEnd?.current && clearTimeout(timerRefMintTimeEnd.current)
        }
    }, [])

    useEffect(() => {
        if (!FREE_STAKE_START_TIMESTAMP) {
            return
        }
        // Timer for start 48 hour stake
        const startTimeout = FREE_STAKE_START_TIMESTAMP - Date.now()
        if (startTimeout > 0) {
            timerRefOne.current = setTimeout(refreshStakeIn48hState, startTimeout)
        }
        // Timer for end 48 hour stake
        const endTimeout = FREE_STAKE_END_TIMESTAMP - Date.now()
        if (endTimeout > 0) {
            timerRefTwo.current = setTimeout(refreshStakeIn48hState, endTimeout)
        }
        return () => {
            timerRefOne?.current && clearTimeout(timerRefOne.current)
            timerRefTwo?.current && clearTimeout(timerRefTwo.current)
        }
    }, [])

    useEffect(() => {
        if (provider) {
            initInfo(provider).then(() => {
            })
        } else {
            cleanInfo()
        }
    }, [provider])

    useEffect(() => {
        refreshStakeIn48hState().then(() => {})
    }, [])

    useEffect(() => {
        if (tokens.length && stakeTokenId) {
            let newTokens:iToken[] = JSON.parse(JSON.stringify(tokens));
            newTokens.filter(token => token.tokenId === stakeTokenId)
                .forEach(token => {
                    let endTime = Math.floor(FREE_STAKE_END_TIMESTAMP / 1000);
                    if (token.premiumEndTime == null || token.premiumEndTime < endTime) {
                        token.premiumEndTime = endTime
                        dispatch(TokensAction(newTokens))
                    }
                })
        }
    }, [tokens, stakeTokenId])

    useEffect(() => {
        if (staking1Membership1Contract && staking2Contract && address.length) {
            refreshStakeOwnedTokenId().then(() => {})
        } else {
            dispatch(StakeTokenIdAction(0))
            dispatch(StakedContractAction(''))
        }
    }, [staking1Membership1Contract, staking2Contract, address])

    useEffect(() => {
        if (passcardContract && staking1Membership1Contract && address.length) {
            refreshTokens(staking1Membership1Contract, passcardContract, address)
            const listener = (_event: Event) => { refreshTokens(staking1Membership1Contract, passcardContract, address)}
            passcardContract.on(passcardContract.filters.Transfer(null, address), listener)
            passcardContract.on(passcardContract.filters.Transfer(address, null), listener)
            staking1Membership1Contract.on(staking1Membership1Contract.filters.MembershipPurchase(address), listener)
            return
        }
        dispatch(TokensAction([]))
    }, [passcardContract, staking1Membership1Contract, address])
    
    useEffect(() => {
        if (staking1Membership1Contract && staking2Contract && address.length) {
            refreshStakeOwnedTokenId().then(() => {})
        }
        if (currentToken && tokens.length) {
            const found = tokens.find(token => token.tokenId === currentToken.tokenId)
            if (found) {
                dispatch(CurrentTokenAction(found))
                return
            }
            const token = orderByField(tokens, {field: 'premiumEndTime', asc: false})[0]
            dispatch(CurrentTokenAction(token))
        }
        if (!currentToken && tokens.length) {
            const token = orderByField(tokens, {field: 'premiumEndTime', asc: false})[0]
            dispatch(CurrentTokenAction(token))
        }
        if (!tokens.length) {
            dispatch(CurrentTokenAction(null))
        }
    }, [tokens])



    const refreshStakeOwnedTokenId = async () => {
        const stakingTokenId = await ownedToken(staking2Contract, address)
        const membershipStakingTokenId = await ownedToken(staking1Membership1Contract, address)
        console.log("stakingTokenId ===>", stakingTokenId)
        console.log("membershipStakingTokenId ===>", membershipStakingTokenId)
        stakingTokenId && dispatch(StakeTokenIdAction(stakingTokenId))
        membershipStakingTokenId && dispatch(StakeTokenIdAction(membershipStakingTokenId))
    }

    const refreshStakeIn48hState = async () => {
        let state: iStakeIn48hState = 'notStart'
        if (!FREE_STAKE_START_TIMESTAMP) {
            dispatch(StakeIn48hStateAction(state))
        }
        const startTime = new Date(FREE_STAKE_START_TIMESTAMP)
        const endTime = new Date(FREE_STAKE_END_TIMESTAMP)
        const now = new Date()
        if (now > endTime) {
            state = 'isClosed'
        }
        if (startTime <= now && now < endTime) {
            state = 'isOpen'
        }
        dispatch(StakeIn48hStateAction(state))
    }

    const loadTokenFromApi = async (address: string): Promise<iToken[]> => {
        const tokens = await getTokens(address)
        return tokens.map(token => {
            const premiumEndTime = Math.floor((token.membershipEndTime ?? 0) / 1000)
            const stakingEndTime = Math.floor((token.stakingEndTime ?? 0) / 1000)
            return {
                tokenId: Number(token.tokenId),
                staking: stakingEndTime !== 0,
                premiumEndTime: Math.max(premiumEndTime, stakingEndTime)
            }
        })
        // return [{
        //     tokenId: 1,
        //     staking: true,
        //     premiumEndTime: Date.now() + 1000000
        // },]
    }

    const refreshTokens = (staking1Membership1Contract: Contract, passcardContract: Contract, address: string) => {
        loadTokenFromApi(address)
            .catch(() => undefined)
            .then(tokens => {
                tokens && dispatch(TokensAction(tokens))
            })
    }

    const initInfo = async (provider: any) => {
        const staking1_membership1_contract = new Contract(STAKING_1_MEMBERSHIP_1_CONTRACT, STAKING_1_MEMBERSHIP_1_ABI, provider)
        const staking1_membership1_contract_signer = new Contract(STAKING_1_MEMBERSHIP_1_CONTRACT, STAKING_1_MEMBERSHIP_1_ABI, (provider as Web3Provider).getSigner())
        const contract_passcard = new Contract(PASSCARD_CONTRACT, PASSCARD_ABI, provider)
        const contract_passcard_signer = new Contract(PASSCARD_CONTRACT, PASSCARD_ABI, (provider as Web3Provider).getSigner())
        const contract_staking = new Contract(STAKING_2_CONTRACT, STAKING_2_ABI, provider)
        const contract_staking_signer = new Contract(STAKING_2_CONTRACT, STAKING_2_ABI, (provider as Web3Provider).getSigner())
        const contract_membership_2 = new Contract(MEMBERSHIP_2_CONTRACT, MEMBERSHIP_2_ABI, provider)
        const contract_membership_2_signer = new Contract(MEMBERSHIP_2_CONTRACT, MEMBERSHIP_2_ABI, (provider as Web3Provider).getSigner())


        const premiumTypes: any = await allPremiumTypes(contract_membership_2)
        const discount: number = await holderDiscount(contract_membership_2)

        fetch.config.baseUrl = API_BASE_URL
        fetch.config.autoLogin = true
        fetch.config.onLogin = onLogin
        fetch.config.withCredentials = true
        dispatch(ProductListAction(premiumTypes))
        dispatch(DiscountAction(discount))
        dispatch(Staking1Membership1ContractAction(staking1_membership1_contract))
        dispatch(Staking1Membership1ContractSignerAction(staking1_membership1_contract_signer))
        dispatch(PasscardContractAction(contract_passcard))
        dispatch(PasscardContractSignerAction(contract_passcard_signer))
        dispatch(Staking2ContractAction(contract_staking))
        dispatch(Staking2ContractSignerAction(contract_staking_signer))
        dispatch(Membership2ContractAction(contract_membership_2))
        dispatch(Membership2ContractSignerAction(contract_membership_2_signer))
    }

    useEffect(() => {
        localStorage.setItem(CURRENT_WALLET, address)
    }, [address])

    const onLogin = async (): Promise<boolean> => {
        if (!address || !provider) {
            return false
        }
        return await lock.acquire(address, async () => {
            const address = localStorage.getItem(CURRENT_WALLET)
            if (!address || !address.length) {
                return false
            }
            const key = `${AUTHORIZATION}-${address}`
            const accessToken = localStorage.getItem(key)
            if (accessToken) {
                return true
            }
            const {message, nonce}: any = await getNonceApi(address, createNonce())

            if (!(provider instanceof Web3Provider)) {
                return false
            }
            const signer = provider.getSigner(address)
            const signature = await signer.signMessage(message);
            const data: any = await walletLoginWithSignApi({
                nonce: nonce,
                credential: signature,
                principal: address
            })
            if (!data) {
                return false
            }
            localStorage.setItem(key, JSON.stringify(data))
            return true
        })
    }

    const cleanInfo = () => {
        dispatch(ProductListAction([]))
        dispatch(Staking1Membership1ContractAction(null))
        dispatch(Staking1Membership1ContractSignerAction(null))
        dispatch(PasscardContractAction(null))
        dispatch(PasscardContractSignerAction(null))
    }

    const isActive = (name: string): boolean => {
        if (['Membership', 'License'].includes(name)) {
            return address.length && staking1Membership1Contract && stakeIn48hState !== 'notStart'
        }
        return true
    }

    return (
        <Row height="64px" className="jc-sb w100" px="70px"
             style={{position: 'fixed', top: 0, zIndex: 999}}
        >
            <Box maxWidth="126px"
                 className='click'
                 onClick={location.pathname.includes('home') ? () => null : () => navigate('/home')}>
                <Image src={svg_logo} height={40} width={126}/>
            </Box>
            <Row width="50%" className="jc-sb">
                {NAV_TABS.map((name: string) => <span
                    style={{
                        opacity: (location.pathname === '/' && name === 'Home') ? 0.8 :
                            (location.pathname.includes(name.toLocaleLowerCase()) ? 0.8 : 0.4)
                    }}
                    key={name}
                    className='white fw700 fz12 click'
                    onClick={() => navigate(`/${name.toLocaleLowerCase()}`)}>{name}</span>)}
                <ConnectWallet/>
            </Row>
        </Row>
    )
}


