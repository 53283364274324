import { useState, useRef } from "react"
import './index.css'
import { FAQList } from '../../config'
import { img_arrow_down, img_arrow_up } from '../../assets/img'


const AccordionItem = (props:any) => {
    const contentEl:any = useRef()
    const { handleToggle, active, faq } = props
    const { title, id, content } = faq

    return (
        <div className="rc-accordion-card">
            <div className="">
                <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                    <h5 className="rc-accordion-title">{title}</h5>
                    <img src={active === id ? img_arrow_up : img_arrow_down} alt="" style={{width: '24px', height: '24px'}}/>
                </div>
            </div>
            <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
                active === id
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }
            }>
                <div className="rc-accordion-body">
                    <p className='mb-0'>{content}</p>
                </div>
            </div>
        </div>
    )
}


export const Accordion = () => {
    
    const [active, setActive] = useState(null)

    const handleToggle = (index:any) => {
        if (active === index) {
            setActive(null)
        } else {
            setActive(index)
        }
    }
    
    return (
        <>
            {FAQList.map((faq, index) => {
                    return (
                        <AccordionItem 
                            key={index} 
                            active={active} 
                            handleToggle={handleToggle} faq={faq} />
                    )
                })
            }
        </>
    )
}

