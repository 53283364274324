import React from 'react'
import { Modal as AntModal } from 'antd'
import { ModalBodyContainer } from './ModalBodyContainer'

interface iModal {
    children: React.ReactNode
    width: number
    visible: boolean
    onCancel: () => void
}

export const Modal:React.FC<iModal> = ({ width, visible, onCancel, children }) => {
    return (
        <>
        {visible && 
        <AntModal 
            closable={false}
            onCancel={onCancel}
            open={visible}
            footer={null}
            maskClosable={true}
            width={`${width}px`}
            bodyStyle={{
                display: 'flex',
                justifyContent: 'center',
                background: 'linear-gradient(129.62deg, #1D1B1B 27.35%, #25252B 104.22%)',
                border: '1px solid rgba(242, 207, 217, 0.3)'
            }}
        >
                {/* <ModalBodyContainer title={title} width={`${width}px`} height={height} onClose={onCancel}>
                    {children}
                </ModalBodyContainer> */}
                <>
                    { children }
                </>
        </AntModal>
        }
        </>
    )
}

