import React from 'react'
import {Box} from '../../../packages'
import {ColorTitle} from '../../../components'
import {Purchase} from './Purchase'
import {RightInterests, StakingContainer} from "./RightInterests";

export const BuyAndStake = () => {

    return (
        <Box mt="696px">
            <ColorTitle title="MEMBERSHIP"/>
            <Purchase/>
            <StakingContainer>
                <RightInterests/>
                {/* <SelectRightsInterests/> */}
            </StakingContainer>
        </Box>
    )
}