import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "@/store/hooks";
import {selectAddress, selectCurrentToken, StakeTokenIdAction, TokensAction} from "@/store/reducer";
import {BUY_PASS_ON_OPENSEA, DOWNLOADS, ONE_DAY_SECONDS} from "@/config";
import {Button, Col, DashLine, MaskBg, Row, SuccessNotification} from "@/components";
import {Box, Image, Text} from "@/packages";
import {openWindow} from "@/util/utils";
import {img_copy} from "@/assets/img";
import {Discord} from "@/pages/License/Discord";
import styled from "styled-components";
import {applyForFreeLicense, getFreeLicenseState, getLicenseApi, getTokens, resetLicenseApi} from "@/request/Api";
import {useCopyToClipboard} from "@/hooks";
import {Confirm} from "@/components/Confirm";
import {iToken} from "@/types";

const DownloadBtn = styled(Box)`
    width: 152px;
    height: 72px;
    background: linear-gradient(129.62deg, #1D1B1B 27.35%, #25252B 104.22%);
    border: 1px solid rgba(242, 207, 217, 0.3);
    cursor: pointer;
`

interface iLicense {
}

export const LicenseStatus: React.FC<iLicense> = ({}) => {
    const [value, copy] = useCopyToClipboard()
    const [open, setOpen] = useState<boolean>(false)
    const [leftDays, setLeftDays] = useState<number>(0)
    const [licenseKey, setLicenseKey] = useState<string>('')
    const [isFreeLicense, setIsFreeLicense] = useState<boolean>(false)
    const [showFreeTrial, setShowFreeTrial] = useState<boolean>(false)
    const [freeTrialConfirmOpen, setFreeTrialConfirmOpen] = useState<boolean>(false)
    const [freeTrialConfirmLoading, setFreeTrialConfirmLoading] = useState<boolean>(false)

    const navigator = useNavigate()
    const dispatch = useAppDispatch()
    const address = useAppSelector(selectAddress)
    const currentToken = useAppSelector(selectCurrentToken)

    useEffect(() => {
        if (currentToken) {
            const days = Math.ceil((currentToken.premiumEndTime - Date.now() / 1000) / ONE_DAY_SECONDS)
            setLeftDays(Math.max(days, 0))
            return
        }
        setLeftDays(0)
    }, [currentToken])

    const onCopy = (e: any, value: string) => {
        e.stopPropagation()
        copy(value).then(() => {
        })
        SuccessNotification('Copied to clipboard.')
    }


    const dcChange = async (success: boolean) => {
        if (success) {
            const license = await getLicenseApi()
            setLicenseKey(license ? license.toString() : '')
        }
    }


    const onReset = async () => {
        const d = await resetLicenseApi()
        if (d) {
            setLicenseKey(d.toString())
            SuccessNotification('License key has been reset.')
        }
        setOpen(false)
    }

    const loadTokenFromApi = async (address: string): Promise<iToken[]> => {
        const tokens = await getTokens(address)
        console.log('tokens ===> ', tokens)
        return tokens.map(token => {
            const premiumEndTime = Math.floor((token.membershipEndTime ?? 0) / 1000)
            const stakingEndTime = Math.floor((token.stakingEndTime ?? 0) / 1000)
            return {
                tokenId: Number(token.tokenId),
                staking: stakingEndTime !== 0,
                premiumEndTime: Math.max(premiumEndTime, stakingEndTime)
            }
        })
    }

    const refreshTokens = (address: string) => {
        loadTokenFromApi(address)
            .catch(() => undefined)
            .then(tokens => {
                tokens && dispatch(TokensAction(tokens))
                // tokens && tokens.forEach(token => {
                //     // token.staking && dispatch(StakeTokenIdAction(token.tokenId))
                //     return
                // })
            })
    }

    const onFreeTrial = async () => {
        if (!address || !currentToken) {
            return
        }
        setFreeTrialConfirmLoading(true)
        const success = await applyForFreeLicense(address, currentToken.tokenId).catch(() => false)
        setIsFreeLicense(success)
        refreshTokens(address)
        setFreeTrialConfirmLoading(false)
        setFreeTrialConfirmOpen(false)
    }

    const loadLicenseState = async () => {
        if (!address || !currentToken || leftDays) {
            setShowFreeTrial(false)
            setIsFreeLicense(false)
            return
        }
        const state = await getFreeLicenseState(address).catch(() => undefined)
        if (!state || !state.testEnable) {
            setShowFreeTrial(false)
            setIsFreeLicense(false)
            return
        }
        !state.testState && setShowFreeTrial(true)
        state.testState && setIsFreeLicense(true)
    }

    useEffect(() => {
        loadLicenseState().then(() => {
        })
    }, [address, currentToken, leftDays])

    if (leftDays) {
        return <MaskBg h={424}>
            <Box className="w100">
                <Row className="jc-sb w100">
                    <Text className='fz16 oct8'>License Key</Text>
                    {licenseKey &&
                        <Row className='click' onClick={(e: any) => onCopy(e, licenseKey)}>
                            <span className='white fz16 oct8 mr10 fw700'>{licenseKey}</span>
                            <Image src={img_copy} height={18} width={18}/>
                        </Row>
                    }
                </Row>
            </Box>
            <Row className='jc-sb w100' mt="16px">
                <Button
                    height="40px"
                    width="244px"
                    name="Reset"
                    type="dark"
                    onClick={() => setOpen(true)}
                />
                <Button
                    height="40px"
                    width="244px"
                    name="Buy"
                    type="light"
                    onClick={() => {
                        navigator('/membership')
                    }}
                />
            </Row>
            <DashLine my="24px"/>
            <Discord dcChange={dcChange}/>

            <DashLine my="24px"/>
            <Text className='fz16 oct8 w100 fx ai-start'>Download</Text>
            <Row className="jc-sb w100" mt="16px">
                {
                    DOWNLOADS.map((item: any) => (
                        <DownloadBtn key={item.name} className="center hover_color" onClick={() => openWindow(item.url)}>
                            <Image src={item.img} width={32} height={32}/>
                            <span className='fz12 fw700 white oct8 ml14 mt4'>{item.name}</span>
                        </DownloadBtn>
                    ))
                }
            </Row>
            <ResetConfirm open={open} onConfirm={onReset} onCancel={() => {
                setOpen(false)
            }}/>
        </MaskBg>
    }

    return <MaskBg h={168}>
        <Col className='ai-start w100'>
            <Row className='w100 jc-sb'>
                <Text className='fz16 oct8'>License Key</Text>
                <span className='white fz16 oct8 mr10 fw700'>-</span>
            </Row>
            <Row className='w100 jc-ct' mt="18px">
                <Button
                    width="240px"
                    onClick={() => navigator('/membership')}
                    name='Get Membership'
                    height="40px"
                    type={'dark'}
                />
            </Row>
        </Col>
    </MaskBg>
}

interface iResetConfirm {
    open: boolean
    onConfirm: () => void
    onCancel: () => void
}

const ResetConfirm: React.FC<iResetConfirm> = ({open, onConfirm, onCancel}) => {
    return <Confirm title={'Reset Your License Key?'} open={open} onConfirm={onConfirm} onCancel={onCancel}/>
}

