import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../store'
import {iWalletState} from '../../types'


const initialState: iWalletState = {
    provider: undefined,
    address: '',
    connecting: false,
    stakeTokenId: 0,
    stakedContract: '',
    stakeIn48hState: 'notStart',
    mintTimeEnded: false
}

export const walletReducer = createSlice({
    name: 'walletReducer',
    initialState,
    reducers: {
        ProviderAction: (state, action: PayloadAction<iWalletState['provider']>) => {
            state.provider = action.payload
        },
        AddressAction: (state, action: PayloadAction<iWalletState['address']>) => {
            state.address = action.payload.toLocaleLowerCase()
        },
        ConnectingAction: (state, action: PayloadAction<iWalletState['connecting']>) => {
            state.connecting = action.payload
        },
        MintTimeEndedAction: (state, action: PayloadAction<iWalletState['mintTimeEnded']>) => {
            state.mintTimeEnded = action.payload
        },
        StakeTokenIdAction: (state, action: PayloadAction<iWalletState['stakeTokenId']>) => {
            state.stakeTokenId = action.payload
        },
        StakedContractAction: (state, action: PayloadAction<iWalletState['stakedContract']>) => {
            state.stakedContract = action.payload
        },
        StakeIn48hStateAction: (state, action: PayloadAction<iWalletState['stakeIn48hState']>) => {
            state.stakeIn48hState = action.payload
        },
    },
})

export const {
    ProviderAction, AddressAction, ConnectingAction, StakeTokenIdAction, StakedContractAction,
    StakeIn48hStateAction, MintTimeEndedAction
} = walletReducer.actions

export const selectProvider = (state: RootState) => state.walletReducer.provider
export const selectAddress = (state: RootState) => state.walletReducer.address
export const selectConnecting = (state: RootState) => state.walletReducer.connecting
export const selectStakeTokenId = (state: RootState) => state.walletReducer.stakeTokenId
export const selectStakedContract = (state: RootState) => state.walletReducer.stakedContract
export const selectStakeIn48hState = (state: RootState) => state.walletReducer.stakeIn48hState
export const selectMintTimeEnded = (state: RootState) => state.walletReducer.mintTimeEnded


export default walletReducer.reducer

