import {Button, Col, ColorTitle, Countdown, ErrorNotification, SuccessNotification, WarningNotification} from '../../../components'
import {Box, Text} from '../../../packages'
import React, {useState} from "react"
import {useAppDispatch, useAppSelector} from '../../../store/hooks'
import {selectAddress, selectCurrentToken, selectPasscardContract, selectPasscardContractSigner, selectStakeIn48hState, selectStakeTokenId, selectStaking2ContractSigner, StakedContractAction, StakeTokenIdAction} from '../../../store/reducer'
import {BUY_PASS_ON_OPENSEA, FREE_STAKE_END_TIMESTAMP, STAKING_2_CONTRACT} from '../../../config'
import {openWindow} from "../../../util/utils"
import {approvalForAll, isApprovedPasscard, stakePasscard} from "../../../request/Contract";
import {TokenSelect} from "../../../components/LeftDays/TokenSelect";

export const FreeStaking = () => {
    const [staking, setStaking] = useState<boolean>(false)

    const address = useAppSelector(selectAddress)
    const currentToken = useAppSelector(selectCurrentToken)
    const stakeTokenId = useAppSelector(selectStakeTokenId)

    const passcardContract = useAppSelector(selectPasscardContract)
    const passcardContractSigner = useAppSelector(selectPasscardContractSigner)
    const staking2ContractSigner = useAppSelector(selectStaking2ContractSigner)
    const stakeIn48hState = useAppSelector(selectStakeIn48hState)

    const dispatch = useAppDispatch()

    const onStaking = async() => {
        if (!currentToken) {
            return
        }
        if (stakeTokenId) {
            WarningNotification('Passcard already staked')
            return
        }
        setStaking(true)
        const tokenId = currentToken.tokenId
        const approved = await isApprovedPasscard(passcardContract, STAKING_2_CONTRACT, address)
        const onsuccess = (tokenId?: number) => {
            tokenId && dispatch(StakeTokenIdAction(tokenId))
            tokenId && dispatch(StakedContractAction('staking'))
            SuccessNotification('Staking Successfully.')
            setStaking(false)
        }
        const onerror = (e: Error) => {
            if (e.message.includes('ACTION_REJECTED')) {
                WarningNotification('Action rejected.')
                setStaking(false)
                return
            }
            console.error(e)
            ErrorNotification('Staking Failed. Please retry.')
            setStaking(false)
        }
        // Check approve and then stake
        if (approved) {
            stakePasscard(staking2ContractSigner, tokenId, onsuccess, onerror)
            return
        }
        approvalForAll(passcardContractSigner, STAKING_2_CONTRACT, () => {
            stakePasscard(staking2ContractSigner, tokenId, onsuccess, onerror)
        }, onerror)
    }

    return (
        <Box className=''>
            { stakeTokenId || stakeIn48hState !== 'isOpen' ?
                <Col>
                    <Col className='slogan_animate_show' mt={'30px'} mb='40px'>
                        <Text className='fz24 oct8'>LANCET, </Text>
                        <Text className='fz24 oct8'>LIGHT IT UP ON CHAIN</Text>
                    </Col>
                </Col>
                : currentToken ?
                    <Col>
                        <ColorTitle title="STAKING" mb="24px"/>
                        <Box mb='24px' style={{pointerEvents: 'all'}}>
                            <TokenSelect hiddenOnlyOne={true} showDays={true}/>
                        </Box>
                        <Countdown eventTime={FREE_STAKE_END_TIMESTAMP / 1000} interval={1000}/>
                        <Box className='center w100'>
                            <Button
                                onClick={onStaking}
                                name="Staking"
                                loading={staking}
                                width="120px"
                                height="40px"
                                type={stakeTokenId ? "disabled" : "light"}
                                mt="32px"
                            />
                        </Box>
                    </Col>
                    :
                    <Col>
                        <Col className='slogan_animate_show' >
                            <Text className='fz24 oct8'>LANCET, </Text>
                            <Text className='fz24 oct8'>LIGHT IT UP ON CHAIN</Text>
                        </Col>
                        <Box className='center w100'>
                            <Button
                                onClick={() => {
                                    openWindow(BUY_PASS_ON_OPENSEA.url)
                                }}
                                name={BUY_PASS_ON_OPENSEA.name}
                                px="24px"
                                minWidth="120px"
                                height="40px"
                                type="light"
                                mt="32px"
                            />
                        </Box>
                    </Col>
            }
        </Box>)
}
