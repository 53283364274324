import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { iLicenseState } from '../../types'


const initialState: iLicenseState = {
  isHoldPasscard: false,
  isMember: false
}

export const licenseReducer = createSlice({
  name: 'licenseReducer',
  initialState,
  reducers: {
    HoldPasscardAction: (state, action: PayloadAction<iLicenseState['isHoldPasscard']>) => {
      state.isHoldPasscard = action.payload
    },
    MemberAction: (state, action: PayloadAction<iLicenseState['isMember']>) => {
      state.isMember = action.payload
    },
  },
})

export const { HoldPasscardAction,MemberAction } = licenseReducer.actions

export const selectHoldPasscard = (state: RootState) => state.licenseReducer.isHoldPasscard
export const selectMember = (state: RootState) => state.licenseReducer.isMember


export default licenseReducer.reducer

