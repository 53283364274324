import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { iMembershipState } from '../../types'


const initialState: iMembershipState = {
    guaranteedPhase: '',
    tokens: [],
    productList: [],
    discount: 100,
    currentToken: null,
    staking1Membership1Contract: null,
    staking1Membership1ContractSigner: null,
    passcardContract: null,
    passcardContractSigner: null,
    staking2Contract: null,
    staking2ContractSigner: null,
    membership2Contract: null,
    membership2ContractSigner: null
}

export const membershipReducer = createSlice({
  name: 'membershipReducer',
  initialState,
  reducers: {
    GuaranteedPhaseAction: (state, action: PayloadAction<iMembershipState['guaranteedPhase']>) => {
      state.guaranteedPhase = action.payload
    },
    TokensAction: (state, action: PayloadAction<iMembershipState['tokens']>) => {
      state.tokens = action.payload
    },
    CurrentTokenAction: (state, action: PayloadAction<iMembershipState['currentToken']>) => {
      state.currentToken = action.payload
    },
    ProductListAction: (state, action: PayloadAction<iMembershipState['productList']>) => {
      state.productList = action.payload
    },
    DiscountAction: (state, action: PayloadAction<iMembershipState['discount']>) => {
      state.discount = action.payload
    },
    Staking1Membership1ContractAction: (state, action: PayloadAction<iMembershipState['staking1Membership1Contract']>) => {
      state.staking1Membership1Contract = action.payload
    },
    Staking1Membership1ContractSignerAction: (state, action: PayloadAction<iMembershipState['staking1Membership1ContractSigner']>) => {
      state.staking1Membership1ContractSigner = action.payload
    },
    PasscardContractAction: (state, action: PayloadAction<iMembershipState['passcardContract']>) => {
      state.passcardContract = action.payload
    },
    PasscardContractSignerAction: (state, action: PayloadAction<iMembershipState['passcardContractSigner']>) => {
      state.passcardContractSigner = action.payload
    },
    Staking2ContractAction: (state, action: PayloadAction<iMembershipState['staking2Contract']>) => {
      state.staking2Contract = action.payload
    },
    Staking2ContractSignerAction: (state, action: PayloadAction<iMembershipState['staking2ContractSigner']>) => {
      state.staking2ContractSigner = action.payload
    },
    Membership2ContractAction: (state, action: PayloadAction<iMembershipState['membership2Contract']>) => {
      state.membership2Contract = action.payload
    },
    Membership2ContractSignerAction: (state, action: PayloadAction<iMembershipState['membership2ContractSigner']>) => {
      state.membership2ContractSigner = action.payload
    },
  },
})

export const {
    GuaranteedPhaseAction, TokensAction, CurrentTokenAction, ProductListAction, Staking1Membership1ContractAction,
    Staking1Membership1ContractSignerAction, PasscardContractAction, PasscardContractSignerAction, Staking2ContractAction, Staking2ContractSignerAction,
    Membership2ContractAction, Membership2ContractSignerAction, DiscountAction
} = membershipReducer.actions

export const selectGuaranteedPhase = (state: RootState) => state.membershipReducer.guaranteedPhase
export const selectTokens = (state: RootState) => state.membershipReducer.tokens
export const selectCurrentToken = (state: RootState) => state.membershipReducer.currentToken
export const selectProductList = (state: RootState) => state.membershipReducer.productList
export const selectDiscount = (state: RootState) => state.membershipReducer.discount
export const selectStaking1Membership1Contract = (state: RootState) => state.membershipReducer.staking1Membership1Contract
export const selectStaking1Membership1ContractSigner = (state: RootState) => state.membershipReducer.staking1Membership1ContractSigner
export const selectPasscardContract = (state: RootState) => state.membershipReducer.passcardContract
export const selectPasscardContractSigner = (state: RootState) => state.membershipReducer.passcardContractSigner
export const selectStaking2Contract = (state: RootState) => state.membershipReducer.staking2Contract
export const selectStaking2ContractSigner = (state: RootState) => state.membershipReducer.staking2ContractSigner
export const selectMembership2Contract = (state: RootState) => state.membershipReducer.membership2Contract
export const selectMembership2ContractSigner = (state: RootState) => state.membershipReducer.membership2ContractSigner


export default membershipReducer.reducer

