import React from 'react'
import {Box, Text} from '../../packages'
import styled from 'styled-components'
import {svg_jagged} from '../../assets/svg'
import {BoxProps} from '../../packages/Box/types'
import {Tooltip} from 'antd'

export const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const Col = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const LinearBox = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(92.16deg, #ECB9D8 -2.04%, #5D63F3 99.99%);
`

export const MaskBg = styled(Box)<{h: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    width: 100%;
    height: ${({h}) => `${h}px`};
    background: linear-gradient(129.62deg, #1D1B1B 27.35%, #25252B 104.22%);
    border: 1px solid rgba(242, 207, 217, 0.3);
    margin-top: 16px;
    z-index: 2;
`
// 虚线分割线
export const DashLine = styled(Box)`
    height: 1px;
    width: 100%;
    border: 1px dashed rgb(78,77,87)
`

export const SolidLine = styled(Box)`
    width: 174px;
    height: 0px;
    opacity: 0.1;
    border: 1px solid #FFFFFF;
`


export const ColorTitle:React.FC<{title: string, fz?: string} & BoxProps> = ({ title, fz, ...props }) => {
    return (
        <Col className='center' {...props}>
            <Text className='linear_color linear_gradient fw400' fontSize={fz || '24px'} mb="18px">{title}</Text>
            <img src={svg_jagged } alt=""/>
        </Col>
    )
}


export const ColorText:React.FC<{title: string, fz?: string} & BoxProps> = ({ title, fz, ...props }) => {
    return (
        <Col {...props}>
            <Text className='linear_color linear_gradient fw300' fontSize={fz || '24px'}>{title}</Text>
            <img src={svg_jagged } alt="" width={10}/>
        </Col>
    )
}

const _DotText = styled.span`
    font-weight: 700;
    font-size: 12px;
    line-height: 160%;
    background: linear-gradient(92.16deg, #ECECEC -2.04%, #B3B2CC 99.99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    opacity: 0.8;
    margin-left: 8px
`
export const DotText:React.FC<{title: string}> = ({ title }) => {
    return (
        <Row>
            <Box width="6px" height="6px" background="linear-gradient(92.16deg, #ECB9D8 -2.04%, #5D63F3 99.99%)" borderRadius="3px"/>
            <_DotText>{title}</_DotText>
        </Row>
    )
}

interface iTip {
    children: React.ReactNode
    title: string
    placement?: any
}
export const Tip:React.FC<iTip> = ({
    children,
    title,
    placement,
}) => {
    return (
        <Tooltip color="linear-gradient(92.16deg, #ECB9D8 -2.04%, #5D63F3 99.99%)" title={title} placement={placement || 'top'}>
            { children }
        </Tooltip>

    )
}