import {Col, ColorTitle, ContainerBg, Row} from '../../components'
import {Box, Image, Text} from '../../packages'
import {img_partner, img_twitter} from '../../assets/img'
import {animated, useSpring} from '@react-spring/web'
import {DURATION} from '../../config'
import {openWindow} from '../../util/utils'

const content = [
    {   twitter: 'https://twitter.com/3twoGroup ',
        img: img_partner, title: '3two Group', desc: `
    3two is an international Web3 company with a strong foundation in 
    Web2. They incubate and provide marketing, technical solutions, 
    strategic consulting, and product design for the projects to 
    successfully set foot within the Web3 world.
    `}
]

export const Partner = () => {
    const [springs0, api0] = useSpring(() => ({ from: { y: -200, opacity: 0 }, to: { y: 0, opacity: 1 }, delay: 0,config: { duration: DURATION } }))

    return (
        <>
            <ContainerBg />
            <Box className='h100 w100 center'>
                <animated.div style={{
                    width: '450px',
                    height: '450px', 
                    paddingTop: "20px",
                    ...springs0,
                }} className="fx-col" >
                    <ColorTitle title="PARTNER"/>
                    <Col className="ai-ct mt32" key={content[0].title} style={{ textAlign: 'center', zIndex: 999 }}>
                        <Image src={content[0].img} height={102} width={102}/>
                        <Row  mt="6px" >
                            <Text className='fz20 oct8' mr="8px">{content[0].title}</Text>
                            <Image src={img_twitter} height={20} width={20} className="click" onClick={() => openWindow(content[0].twitter)}/>
                        </Row>
                        <Text className='fw400 fz12 oct6' mt="12px" lineHeight="240%">{content[0].desc}</Text>
                    </Col>
                </animated.div>
            </Box>
        </>
    )
}