import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Box, Text} from '../../packages'
import {Row} from '../index'
import styled from 'styled-components'
import moment from 'moment'

export const calculateDuration = (et: number) => moment.duration(Math.max(et - Math.floor(Date.now() / 1000), 0), 'seconds')


const ItemWrapper = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 40px;
    background:  linear-gradient(129.62deg, #1D1B1B 27.35%, #25252B 104.22%);
    border: 1px solid rgba(242, 207, 217, 0.3);
`
interface iCountdown {
    // Notice Second
    eventTime: number
    interval: number
    onEnding?: () => void
}

export const Countdown: React.FC<iCountdown> = ({ eventTime, interval, onEnding }) => {
    const [duration, setDuration] = useState(calculateDuration(eventTime))
    const timerRef: any = useRef(0)

    const timerCallback = useCallback(() => {
      setDuration(calculateDuration(eventTime))
    }, [eventTime])
  
    useEffect(() => {
      timerRef.current = setInterval(timerCallback, interval)
      return () => {
        clearInterval(timerRef.current)
      }
    }, [eventTime])
    
    useEffect(() => {
        if (Math.ceil(duration.asDays()) === 0 && duration.hours() === 0 && duration.minutes() === 0 && duration.seconds() === 0) {
            onEnding && onEnding()
        }
    },[duration])
    return (
        <Box className='fx-row ai-ct jc-sb'>
            { 
                [`${Math.ceil(duration.asDays())}D`,`${duration.hours()}H`,`${duration.minutes()}M`,`${duration.seconds()}S`].map((item:string, index: number) => (
                    <div key={item}>
                    { index === 0 && item === '0D' ? null : (
                        <Row>
                            <ItemWrapper>
                                <Text className='fz18 fw700 ctc8'>{item}</Text>
                            </ItemWrapper>
                            { index !==3 && <Text className='fz18 fw700 ctc6 center' minWidth="20px">:</Text>}
                        </Row>
                    )}
                    </div>
                ))
            }
        </Box>
        
    )
}
