export interface OrderBy {
    field: string,
    asc: boolean
}

export type OrOrderBy = OrderBy | null | undefined

export interface iBtn {
    loading: boolean
    active: boolean
}
export const initBtn = {
    loading: false,
    active: false
}