import { notification } from 'antd'
import { img_success, img_warning, img_error, img_close } from '../../assets/img'


export const SuccessNotification = (description: string) => {
  return OpenNotification('Success', description, 'success')
}

export const ErrorNotification = (description: string) => {
  return OpenNotification('Error', description, 'error')
}

export const WarningNotification = (description: string) => {
  return OpenNotification('Warning', description, 'warning')
}

export const OpenNotification = (title: string, description?: string ,type?: string, duration?: string, key?: string) => {
  
  let typeMsg = ''
  switch (type) {
    case 'info':
      typeMsg = ''
      break
    case 'success':
      typeMsg = img_success
      break
    case 'warning':
      typeMsg = img_warning
      break
    case 'error':
      typeMsg = img_error
      break
  }
  if(key === undefined) {
    key = 'lancet'
    notification.close('lancet')
  }
  notification.open({
    maxCount: 1,
    message: <span className='fz16 fw700 oct8' style={{whiteSpace: 'pre-wrap', color: '#fff'}}>{title}</span>,
    description: description ? <span className='fz14 fw700 oct6' style={{whiteSpace: 'pre-wrap', color: '#fff'}}>{description}</span> : <span></span>,
    className: 'antdNotificationClassName',
    icon: <>{typeMsg ? <img src={typeMsg} height={16} width={16} alt="" /> : null }</>,
    closeIcon: <img src={img_close} height={16} width={16} alt="" className=''/>,
    duration: duration === 'never' ? null : 5,
    key
  })
}

export const CloseNotification = (key: string) => {
  notification.close(key)
}