import {Contract, Event} from "@ethersproject/contracts";
import {GAS_LIMIT} from "../config";
import {BigNumber} from "@ethersproject/bignumber";
import {ContractReceipt, ContractTransaction} from "@ethersproject/contracts/src.ts/index";
import {parseEther} from "@ethersproject/units";

export const findEvent = (name: string, events?: Event[]): Event | undefined => {
    if (events?.length) {
        return events.find(event => event?.event === name)
    }
}

const fetch = (promise: Promise<ContractTransaction>, success: (events?: Event[]) => void, failed: (e: any) => void) => {
    try {
        promise.then((response: ContractTransaction) => {
            console.log('transaction response ===> ', response)
            if (response) {
                response.wait()
                    .then((receipt: ContractReceipt) => {
                        console.log('transaction receipt ===> ', receipt)
                        receipt?.status ? success(receipt.events) : failed('Transaction failed')
                    })
                    .catch(failed)
                return
            }
            return failed('transaction response is empty')
        }).catch(failed)
    } catch (e) {
        failed(e)
    }
}

export const ownedToken = async (contract: Contract, address: string): Promise<number> => {
    let ownedToken: BigNumber = await contract.ownedToken(address)
    console.log('ownedToken ===> ', ownedToken.toNumber(), address)
    return ownedToken.toNumber()
}

export const allPremiumTypes = async (contract: Contract): Promise<any> => {
    //return await contract.getAllMembershipTypes() || []
    return [
        {
            membershipDays: BigNumber.from(7),
            membershipOriginPrice: parseEther("0.037"),
            membershipHolderPrice: parseEther("0.02553"),
            membershipPrice:  parseEther("0.037"),
            onSale: true
        },
        {
            membershipDays: BigNumber.from(30),
            membershipOriginPrice: parseEther("0.1"),
            membershipHolderPrice: parseEther("0.069"),
            membershipPrice:  parseEther("0.1"),
            onSale: true
        },
        {
            membershipDays: BigNumber.from(180),
            membershipOriginPrice: parseEther("0.54"),
            membershipHolderPrice: parseEther("0.3726"),
            membershipPrice:  parseEther("0.54"),
            onSale: true
        },
        {
            membershipDays: BigNumber.from(360),
            membershipOriginPrice: parseEther("0.88"),
            membershipHolderPrice: parseEther("0.6072"),
            membershipPrice:  parseEther("0.88"),
            onSale: false
        }
    ]
}

export const holderDiscount = async (contract: Contract): Promise<number> => {
    return await contract.holderDiscount() || 100
}

export const isApprovedPasscard = async (contract: Contract, targetContractAddress: string, address: string): Promise<boolean> => {
    return await contract.isApprovedForAll(address, targetContractAddress)
}

export const approvalForAll = (contractSigner: Contract, targetContractAddress: string, success: () => void, failed: (e: any) => void) => {
    fetch(contractSigner.setApprovalForAll(targetContractAddress, true, {gasLimit: GAS_LIMIT}), success, failed)
}

export const stakePasscard = (contractSigner: Contract, tokenId: number, success: (tokenId?: number) => void, failed: (e: any) => void) => {
    const handleSuccess = (events?: Event[]) => {
        const event = findEvent('Staking', events)
        event?.args?.tokenId ? success(event.args.tokenId.toNumber()) : success()
    }
    fetch(contractSigner.staking(tokenId, {gasLimit: GAS_LIMIT}), handleSuccess, failed)
}

export const premium = (contractSigner: Contract, days: number, tokenId: number | undefined, price: BigNumber, success: () => void, failed: (e: any) => void) => {
    if (tokenId && tokenId != 10000) {
        fetch(contractSigner.holderMembershipPurchase(days, tokenId, {value: price, gasLimit: GAS_LIMIT}), success, failed)
    } else {
        fetch(contractSigner.userMembershipPurchase(days, {value: price, gasLimit: GAS_LIMIT}), success, failed)
    }

}