import React, {useEffect, useState} from 'react'
import {Button, Col, OpenNotification, Row} from '../../components'
import {Text} from '../../packages'
import styled from 'styled-components'
import {getMemberInfoApi, saveDiscordApi} from '../../request/Api'
import {useAppSelector} from "../../store/hooks";
import {selectAddress} from "../../store/reducer";

const TextMsg = styled(Text)`
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    background: linear-gradient(92.16deg, #ECB9D8 -2.04%, #5D63F3 99.99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    opacity: 0.8;
    margin-top: 16px;
`
interface iDiscord {
    dcChange: (success: boolean) => void
}
export const Discord:React.FC<iDiscord> = ({dcChange }) => {
    const [dc, setDc] = useState<string | undefined>()
    const [discord, setDiscord] = useState<any>({ value: '', active: false, loading: false })

    const address = useAppSelector(selectAddress)

    useEffect(() => {
        if (!address.length) {
            setDc(undefined)
            return
        }
        setDc(undefined)
        getMemberInfoApi().then((info: any) => {
            if (!info?.discordId) {
                setDc(undefined)
                return
            }
            setDc(info.discordId)
            setDiscord({value: info.discordId, active: true, loading: false })
        }).catch(e => {
            setDc(undefined)
        })
    }, [address])

    useEffect(() => {
        dcChange(!!dc)
    }, [dc])

    const onSaveDiscord = async () => {
        console.log('discord', discord)
        if (discord.value) {
            setDiscord((d: any) => ({...d, active: false, loading: true}))
            const success  = await saveDiscordApi(discord.value).catch(() => false)
            if (success) {
                OpenNotification('Successful', 'Discord saved.', 'success')
                setDiscord({value: '', active: false, loading: false})
                setDc(discord.value)
            }else {
                setDiscord({value: discord.value, active: true, loading: false} )
                setDc(undefined)
            }
        }
    }

    const onChange = (e: any) => {
        const value = e.target.value
        // const reg: any = /^[\w\- .]+#+\d{2,20}|\d{1,30}$/
        const reg:any = /^\d{2,20}$|^\d{1,30}$/

        setDiscord({ value, active: reg.test(value), loading: false })
    }

    return (
        <Col className='w100'>
            <Row className="jc-sb w100">
                <Text className='fz16 oct8'>Discord ID</Text>
                {
                    dc ?
                    <Text className='fz16 oct4 white click' onClick={() => {
                        setDiscord({value: dc, active: true, loading: false })
                        setDc(undefined)
                    }} >{dc}</Text> :
                    <Row>
                        <input
                            defaultValue={discord.value}
                            onChange={onChange}
                            placeholder='Please Enter'
                            className="input_style"
                            style={{width: '200px'}}/>
                        <Button
                            ml="8px"
                            height="40px"
                            width="80px"
                            name="Save"
                            loading={discord.loading}
                            onClick={discord.active ? () => onSaveDiscord() : () => null}
                            type={discord.active ? 'light' : 'dark'}
                        />
                    </Row>
                }
            </Row>
            { !dc && <TextMsg>*Please submit your Discord ID to have access to license key.</TextMsg>}
        </Col>
    )
}