import React, {useCallback, useEffect, useState} from 'react'
import {img_coinbase, img_metamask, img_wallet_connect} from '../../assets/img'
import {Box, Image, Text} from '../../packages'
import {Col, Modal} from '../../components'
import styled from 'styled-components'
import {getName} from '../../util/utils'
import {useWeb3React} from '@web3-react/core'
import {hooks as metaMaskHooks, metaMask} from '../../connectors/metaMask'
import {coinbaseWallet, hooks as coinbaseHooks} from '../../connectors/coinbaseWallet'
import {hooks as walletConnectHooks, walletConnect} from '../../connectors/walletConnect'
import {Accounts} from './Accounts'
import {AddressAction, ConnectingAction, ProviderAction, selectAddress, selectConnecting, selectProvider} from '../../store/reducer'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {CHAIN_ID} from "../../config";


const {useProvider: useProvider_metamask, 
    useAccounts: useAccounts_metamask, 
    useIsActivating: useIsActivating_metamask, 
    useIsActive: useIsActive_metamask, 
    useChainId: useChainId_metamask } = metaMaskHooks

const {useProvider: useProvider_coinbase, 
    useAccounts: useAccounts_coinbase, 
    useIsActivating: useIsActivating_coinbase, 
    useIsActive: useIsActive__coinbase,
    useChainId: useChainId_coinbase,} = coinbaseHooks

const {useProvider: useProvider_walletConnect, 
    useAccounts: useAccounts_walletConnect, 
    useIsActivating: useIsActivating_walletConnect, 
    useIsActive: useIsActive_walletConnect,
    useChainId: useChainId_walletConnect,} = walletConnectHooks

interface iWallets {
    id: number
    logo: string
    name: string
}

const WalletItem = styled(Box)<{ h: number }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: ${({h}) => `${h}px`};
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
`
const CancelBtn = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 40px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid rgba(242, 207, 217, 0.4);
    cursor: pointer;
`
const SwitchNetWrapper = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 64px;
    position: absolute;
    left: 0px;
    top: 64px;
    cursor: pointer;
    background: linear-gradient(92.16deg, rgba(236, 185, 216, 0.4) -2.04%, rgba(93, 99, 243, 0.4) 99.99%);
`
const SwitchNetBtn = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 40px;
    background: linear-gradient(92.16deg, #ECECEC -2.04%, #B3B2CC 99.99%);
    border: 1px solid rgba(242, 207, 217, 0.3);
    margin-left: 48px
`
const SwitchNetText = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    background: linear-gradient(129.62deg, #1D1B1B 27.35%, #25252B 104.22%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    opacity: 0.8;
`
const wallets: iWallets[] = [
    {id: 1, logo: img_metamask, name: 'MetaMask'},
    {id: 2, logo: img_wallet_connect, name: 'WalletConnect'},
    {id: 3, logo: img_coinbase, name: 'Coinbase'}
]
export const ConnectWallet = () => {
    const [open, setOpen] = useState<boolean>(false)
    const [chainId, setChainId] = useState<number | undefined>(undefined)
    const { connector} = useWeb3React()
    const dispatch = useAppDispatch()
    const _address = useAppSelector(selectAddress)
    const _provider = useAppSelector(selectProvider)
    const _connecting = useAppSelector(selectConnecting)

    
    
    const provider_metamask = useProvider_metamask()
    const accounts_metamask = useAccounts_metamask()
    const isActivating_metamask = useIsActivating_metamask()
    const isActive_metamask = useIsActive_metamask()
    const chainId_metamask = useChainId_metamask()
    
    const provider_coinbase = useProvider_coinbase()
    const accounts_coinbase = useAccounts_coinbase()
    const isActivating_coinbase = useIsActivating_coinbase()
    const isActive_coinbase = useIsActive__coinbase()
    const chainId_coinbase = useChainId_coinbase()

    const provider_walletConnect = useProvider_walletConnect()
    const accounts_walletConnect = useAccounts_walletConnect()
    const isActivating_walletConnect = useIsActivating_walletConnect()
    const isActive_walletConnect = useIsActive_walletConnect()
    const chainId_walletConnect = useChainId_walletConnect()

    useEffect(() => {     
        void metaMask.connectEagerly().catch(() => {
            console.debug('Failed to connect eagerly to metamask')
        })
        void coinbaseWallet.connectEagerly().catch(() => {
            console.debug('Failed to connect eagerly to coinbase wallet')
        })
        void walletConnect.connectEagerly().catch(() => {
            console.debug('Failed to connect eagerly to wallet connect')
        })
    }, [])

    const onClose = () => {
        dispatch(ConnectingAction(false))
    }
    const onOpen = () => {
        if (!isActivating_metamask && !isActivating_coinbase && !isActivating_walletConnect) {
            dispatch(ConnectingAction(true))
        }
    }
    const onConnect = (name: string) => {
        name === 'MetaMask' && metaMask.activate(CHAIN_ID)
        name === 'Coinbase' && coinbaseWallet.activate(CHAIN_ID)
        name === 'WalletConnect' && walletConnect.activate(CHAIN_ID)
        onClose()
    }
    const disConnect = () => {
        if (connector?.deactivate) {
            void connector.deactivate()
            dispatch(AddressAction(''))
            dispatch(ProviderAction(undefined))
        } else {
            void connector.resetState()
            dispatch(AddressAction(''))
            dispatch(ProviderAction(undefined))
        }
    }
    
   

    useEffect(() => {
        setOpen(_connecting)
    }, [_connecting])


    useEffect(() => {
        if (isActive_metamask || isActive_coinbase || isActive_walletConnect) {
            if(isActive_metamask) {
                setChainId(chainId_metamask)
                dispatch(ProviderAction(provider_metamask))
            }
            if(isActive_coinbase) {
                setChainId(chainId_coinbase)
                dispatch(ProviderAction(provider_coinbase))
            }
            if(isActive_walletConnect) {
                setChainId(chainId_walletConnect)
                dispatch(ProviderAction(provider_walletConnect))
            }   
        } else {
            disConnect()
        }
    }, [isActive_metamask,
        isActive_coinbase, 
        isActive_walletConnect, 
        chainId_metamask,
        chainId_coinbase,
        chainId_walletConnect])

    const onSwitchNet = useCallback(async() => {            
        if(_provider) {
            await connector.provider?.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: '0x1' }],
            })
        }
    },[_provider])

    return (
        < >
            {(isActive_metamask || isActive_coinbase || isActive_walletConnect) ?
                <Accounts disConnect={disConnect} name={getName(connector)}/> :
                <Box className='connect_wallet center click' onClick={onOpen}>
                    <span className='white fz12 fw700'>Connect Wallet →</span>
                </Box>
            }
            { 
                chainId && chainId !== 1 && (
                    <SwitchNetWrapper onClick={onSwitchNet} className='switch_net'>
                        <Text className='fz16 white oct6'>Switch to Ethereum Chain for Using Lancet. </Text>
                        <SwitchNetBtn>
                            <SwitchNetText>SWITCH NETWORK</SwitchNetText>
                        </SwitchNetBtn>
                    </SwitchNetWrapper>
                )
            }
            <Modal
                width={320}
                visible={open}
                onCancel={onClose}
            >
                <Box height="428px">
                    <Box className='w100 center'>
                        <Text className='linear_gradient linear_color fz20'>Connect Wallet</Text>
                    </Box>
                    <Col mt="40px">
                        {
                            wallets.map((item: iWallets) => (
                                <WalletItem h={68} key={item.id} className="hover_color" onClick={() => onConnect(item.name)}>
                                    <Image src={item.logo} height={24} width={24}/>
                                    <span className='white fw700 ml8 fz14 oct8'>{item.name}</span>
                                </WalletItem>
                            ))
                        }
                    </Col>
                    <CancelBtn mt="32px" onClick={() => onClose()}>
                        <span className='white fz14 fw700 oct8'>Cancel</span>
                    </CancelBtn>
                </Box>
            </Modal>
        </>
    )
}

