import React, {useEffect, useState} from 'react'
import {Box} from '../../packages'
import {cutAddress} from '../../util/utils'
import {hooks as metaMaskHooks} from '../../connectors/metaMask'
import {hooks as coinbaseHooks} from '../../connectors/coinbaseWallet'
import {hooks as walletConnectHooks} from "../../connectors/walletConnect";
import {AddressAction} from '../../store/reducer'
import {useAppDispatch} from '../../store/hooks'
import styled from 'styled-components'
import {Popover} from 'antd'

const { useAccounts: useAccounts_metamask, useIsActivating: useIsActivating_metamask, useIsActive: useIsActive_metamask } = metaMaskHooks
const { useAccounts: useAccounts_coinbase, useIsActivating: useIsActivating_coinbase, useIsActive: useIsActive__coinbase} = coinbaseHooks
const { useAccounts: useAccounts_walletConnect, useIsActivating: useIsActivating_walletConnect, useIsActive: useIsActive_walletConnect} = walletConnectHooks


const DisConnect = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 167px;
    height: 40px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%);
    cursor: pointer;
`

interface iAccounts {
    disConnect: () => void
    name: string
}
export const Accounts:React.FC<iAccounts> = ({
    disConnect,
    name
}) => {
    const dispatch = useAppDispatch()
    const [account, setAccount] = useState<string>('')
    const accounts_metamask = useAccounts_metamask()
    const accounts_coinbase = useAccounts_coinbase()
    const accounts_walletConnect = useAccounts_walletConnect()

    useEffect(() => {
        switch (name) {
            case 'MetaMask':
                if(accounts_metamask) {
                    const lastOneAddress = accounts_metamask[0]
                    setAccount(cutAddress(lastOneAddress))
                    dispatch(AddressAction(lastOneAddress))
                }
                break;
            case 'Coinbase Wallet':
                if(accounts_coinbase) {
                    const lastOneAddress = accounts_coinbase[0]
                    setAccount(cutAddress(lastOneAddress))
                    dispatch(AddressAction(lastOneAddress))
                }
                break;
            case 'WalletConnect':
                if(accounts_walletConnect) {
                    const lastOneAddress = accounts_walletConnect[0]
                    setAccount(cutAddress(lastOneAddress))
                    dispatch(AddressAction(lastOneAddress))
                }
                break;
            default:
                break;
        }
    },[name, accounts_metamask, accounts_coinbase, accounts_walletConnect])

    return (
        <Popover content={
            <DisConnect>
                <span className='white fz12 fw700'  onClick={disConnect}>Disconnect</span>
            </DisConnect>
        }>
            <Box className='disconnect_wallet center click' >
                <span className='white fz12 fw700' >{ account }</span>
            </Box>
        </Popover>
    )
}