import React, {useEffect} from 'react'
import {Box} from '../../packages'
import {ContainerBg} from '../../components'
import {BuyAndStake} from './components'
import {DURATION, membership_left_1, membership_left_2, membership_right_1, membership_right_2} from '../../config'
import {useAppSelector} from '../../store/hooks'
import {useNavigate} from 'react-router-dom'
import {selectAddress} from "../../store/reducer"
import {animated, useSpring} from '@react-spring/web'

export const Membership = () => {
    const [springs0, api0] = useSpring(() => ({ from: { opacity: 0 }, to: { opacity: 1 }, config: { duration: DURATION+500 } }))
    const navigator = useNavigate()
    const address = useAppSelector(selectAddress)

    useEffect(() => {
        if (!address) {
            navigator('/home')
        }
    }, [address])
   
    return (
        <Box className='fx jc-ct'>
            <ContainerBg
                left1={membership_left_1}
                left2={membership_left_2}
                right1={membership_right_1}
                right2={membership_right_2}
            />

            <animated.div  className='fx-col ai-ct absolute_center' style={{...springs0}}>
                <BuyAndStake/>
            </animated.div>
        </Box>
    )
}