import {Select} from "antd"
import {DownOutlined} from '@ant-design/icons'
import {Box, Image, Text} from '../../packages'
import {useAppDispatch, useAppSelector} from "../../store/hooks"
import {CurrentTokenAction, selectCurrentToken, selectStakeTokenId, selectTokens} from "../../store/reducer"
import {iToken} from "../../types"
import {leftDaysFromNow, openWindow} from "../../util/utils"
import {Button, Col, Row} from '../../components'
import {img_lock, img_unlock} from '../../assets/img'
import {BUY_PASS_ON_OPENSEA} from "@/config";
import React from "react";


export const TokenSelect = ({hiddenOnlyOne, showDays}: {hiddenOnlyOne?: boolean, showDays?: boolean}) => {
    const dispatch = useAppDispatch()
    const tokens = useAppSelector(selectTokens)
    const currentToken = useAppSelector(selectCurrentToken)
    const stakeTokenId = useAppSelector(selectStakeTokenId)

    hiddenOnlyOne = hiddenOnlyOne === undefined ? false : hiddenOnlyOne
    showDays = showDays === undefined ? true : showDays

    const hasDays = (tokens: iToken[]) => {
        return tokens.some(item => item.premiumEndTime > 0)
    }

    const handleChange = (value: any) => {
        const token = tokens.find((item) => item.tokenId === value)
        if (token) {
            dispatch(CurrentTokenAction(token))
            return
        }
        dispatch(CurrentTokenAction(null))
    }

    const dropdownRender = () => {
        return (
            <Col background="linear-gradient(129.62deg, #1D1B1B 27.35%, #25252B 104.22%)" >
                {
                    tokens.map((item: iToken) => (
                        <Row 
                            height="32px" 
                            border="1px solid rgba(242, 207, 217, 0.3)" 
                            mt="1px"
                            pl="12px"
                            className="hover_color click"
                            onClick={() => handleChange(item.tokenId)}
                            background={Number(item.tokenId) === Number(currentToken?.tokenId) ? 'linear-gradient(92.16deg, #ECB9D8 -2.04%, #5D63F3 99.99%)' : 'transparent'}
                            >
                            <Image src={stakeTokenId === Number(item.tokenId) ? img_lock : img_unlock} height={16} width={16} />
                            <Text className="fz14 white oct8" ml="4px">{showDays && item.premiumEndTime > 0 ? `#${item.tokenId} (${leftDaysFromNow(item.premiumEndTime)}days)` : `#${item.tokenId}`}</Text>
                        </Row>
                    ))
                }
            </Col>
        )
    }
    if (tokens.length === 1 && hiddenOnlyOne === true) {
        return <Box ml="8px" className='center'></Box>
    }

    return <Box className='center'>
        { tokens.length > 1 ?
            <Select
                defaultValue={currentToken?.tokenId ? `#${currentToken.tokenId}` : undefined}
                key={currentToken?.tokenId}
                style={showDays && hasDays(tokens) ? { width: 160 } : { width: 110 }}
                suffixIcon={<DownOutlined style={{ marginTop: '5px', fontSize: '10px', color: '#fff' }}/>}
                dropdownRender={dropdownRender}
            />
            :
            <>
                { currentToken?.tokenId && currentToken?.tokenId != 10000 ? (
                        <Row>
                            
                            <Image src={stakeTokenId === currentToken?.tokenId ? img_lock : img_unlock} height={16} width={16} />
                            <Text className='fz14 oct8 fw700 white' ml="4px">{`#${currentToken.tokenId}`}</Text>
                        </Row>
                    ) :
                    <Row>
                    <Button
                        padding={"0px 10px"}
                        width="100%"
                        maxWidth="240px"
                        onClick={() => openWindow(BUY_PASS_ON_OPENSEA.url)}
                        name={BUY_PASS_ON_OPENSEA.name}
                        height="40px"
                        type={'dark'}
                        loading={false}/>
                    </Row>
                }
            </>
        }
    </Box>
}